.page-template-template-artGallery {
	.contentinner {
		.contentarea.gencontentstyles {
			padding : 0px 0px;

			.artdeptlanding {
				background-color : @black;
				
				.page-header {
					margin-top : 0px;
					position : relative;
					.gradient-blacksubtle;
					
					.post-edit-link {
						display : inline; 
						position : absolute;
						top: 10px; 
						right : 10px; 
						z-index : 400;
					}
				}
	
				// Main Landing Page header
				.headingbox {
					padding : 10px 20px;
			    	margin : 10px auto 30px auto;
			    	max-width : 1000px;
					font-family : @font-family-amatic !important;
					font-size : @font-size-bromello;
					color : @white;
					
					.headingboxtitle {
						padding : 0px 20px;
						
						h2 {
							margin-top : 0px !important;
							margin-bottom : 0px !important;
							font-family : @font-family-bromello !important;
							font-size : @font-size-bromello !important;
							font-size : 3em !important;
							line-height : 0.95em !important;
							text-align : left;
						}
					}
					.headingboxtext {
						padding : 40px 20px 20px 20px;
						font-size : @font-size-h3;
						line-height : @font-size-h2;
						text-align : justify;
					}
				}
    
				// Artists of the Week / Month
			    .frameframe {
			    	//width : 100%;
			    	margin : 0px auto 20px auto;
			    	max-width : 1000px;
			    	position : relative;
			    	background-color : @white;
			    	
			    	-webkit-box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.5);
					-moz-box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.5);
					box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.5);
			    	
				    -ms-transform: rotate(0.75deg); /* IE 9 */
				    -webkit-transform: rotate(0.75deg); /* Chrome, Safari, Opera */
				    transform: rotate(0.75deg);
				    
				    &.frame001 {
					    -ms-transform: rotate(0.45deg); /* IE 9 */
					    -webkit-transform: rotate(0.45); /* Chrome, Safari, Opera */
					    transform: rotate(0.45);
				    }
				    &.frame002 {
					    -ms-transform: rotate(-0.25deg); /* IE 9 */
					    -webkit-transform: rotate(-0.25deg); /* Chrome, Safari, Opera */
					    transform: rotate(-0.25deg);
				    }
				    &.frame003 {
					    -ms-transform: rotate(0.35deg); /* IE 9 */
					    -webkit-transform: rotate(0.35deg); /* Chrome, Safari, Opera */
					    transform: rotate(0.35deg);
				    }
				    &.photo001 {
					    -ms-transform: rotate(-0.40deg); /* IE 9 */
					    -webkit-transform: rotate(-0.40deg); /* Chrome, Safari, Opera */
					    transform: rotate(-0.40deg);
				    }
			    	
			    	.frametop {
			    		background-image : url(../img/Frame-Top.jpg);
			    		background-position : top;
			    		background-repeat : repeat-x;
			    		width : 100%;
			    		height : 100%;
			    	}    	
			    	.framebottom {
			    		background-image : url(../img/Frame-Bottom.jpg);
			    		background-position : bottom;
			    		background-repeat : repeat-x;
			    		width : 100%;
			    		height : 100%;
			    	}    	
			    	.frameleft {
			    		background-image : url(../img/Frame-Left.jpg);
			    		background-position : left;
			    		background-repeat : repeat-y;
			    		width : 100%;
			    		height : 100%;
			    	}    	
			    	.frameright {
			    		background-image : url(../img/Frame-Right.jpg);
			    		background-position : right;
			    		background-repeat : repeat-y;
			    		width : 100%;
			    		height : 100%;
			    	}
			    	
			    	.frametopleft {
			    		background-image:url(../img/Frame-TopLeft.jpg);
			    		width : 137px;
			    		height : 141px;
			    		position : absolute;
			    		top : 0px;
			    		left : 0px;
			    	}
			    	.frametopright {
			    		background-image:url(../img/Frame-TopRight.jpg);
			    		width : 137px;
			    		height : 141px;
			    		position : absolute;
			    		top : 0px;
			    		right : 0px;
			    	}
			      	.framebottomleft {
			    		background-image:url(../img/Frame-BottomLeft.jpg);
			    		width : 137px;
			    		height : 141px;
			    		position : absolute;
			    		bottom : 0px;
			    		left : 0px;
			    	}
			    	.framebottomright {
			    		background-image:url(../img/Frame-BottomRight.jpg);
			    		width : 137px;
			    		height : 141px;
			    		position : absolute;
			    		bottom : 0px;
			    		right : 0px;
			    	}
			    	
			    	.framecontent {
			    		padding : 150px 150px;
			    		font-family : @font-family-bromello !important;
			    		position : relative;
			    		
			    		h3 {
			    			font-size : 1.5em;
			    			text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			    			text-align : center;
			    			
			    			small {
			    				font-size : 0.75em;
			    			}
			    		}
			    		.aotwcontent {
			    			
			    			.aotydetails {
			    				text-align : center;
			    				font-family : @font-family-amatic !important;
			    				font-size : 2em;
			    			}
			    		}
			    		.aotycontent {
			    			
			    			.aotydetails {
			    				text-align : center;
			    				font-family : @font-family-amatic !important;
			    				font-size : 2em;
			    			}
			    			.potydetails {
			    				text-align : center;
			    				font-family : @font-family-amatic !important;
			    				font-size : 2em;
			    			}
			    		}
			    	}
			    }
			    
				// Photographer of the Week / Month
			    .polaroidframe { // NOT USED ANYMORE - PHOTOS NOW HAVE FRAME STYLING
			    	//width : 100%;
			    	margin : 50px auto 0px auto;
			    	max-width : 1000px;
			    	position : relative;
			    	//background-color : @white;
			    	background-color : #F9F9F9;
			    	
			    	-webkit-box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.5);
					-moz-box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.5);
					box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.5);
			    	
				    -ms-transform: rotate(-0.75deg); /* IE 9 */
				    -webkit-transform: rotate(-0.75deg); /* Chrome, Safari, Opera */
				    transform: rotate(-0.75deg);
			    	
			    	.polaroidtop {
			    		background-image : url(../img/Polaroid-Top.jpg);
			    		background-position : top;
			    		background-repeat : repeat-x;
			    		width : 100%;
			    		height : 100%;
			    	}    	
			    	.polaroidbottom {
			    		background-image : url(../img/Polaroid-Bottom.jpg);
			    		background-position : bottom;
			    		background-repeat : repeat-x;
			    		width : 100%;
			    		height : 100%;
			    	}    	
			    	.polaroidleft {
			    		background-image : url(../img/Polaroid-Left.jpg);
			    		background-position : left;
			    		background-repeat : repeat-y;
			    		width : 100%;
			    		height : 100%;
			    	}    	
			    	.polaroidright {
			    		background-image : url(../img/Polaroid-Right.jpg);
			    		background-position : right;
			    		background-repeat : repeat-y;
			    		width : 100%;
			    		height : 100%;
			    	}
			    	
			    	.polaroidtopleft {
			    		background-image:url(../img/Polaroid-TopLeft.jpg);
			    		width : 40px;
			    		height : 40px;
			    		position : absolute;
			    		top : 0px;
			    		left : 0px;
			    	}
			    	.polaroidtopright {
			    		background-image:url(../img/Polaroid-TopRight.jpg);
			    		width : 40px;
			    		height : 40px;
			    		position : absolute;
			    		top : 0px;
			    		right : 0px;
			    	}
			      	.polaroidbottomleft {
			    		background-image:url(../img/Polaroid-BottomLeft.jpg);
			    		width : 40px;
			    		height : 40px;
			    		position : absolute;
			    		bottom : 0px;
			    		left : 0px;
			    	}
			    	.polaroidbottomright {
			    		background-image:url(../img/Polaroid-BottomRight.jpg);
			    		width : 40px;
			    		height : 40px;
			    		position : absolute;
			    		bottom : 0px;
			    		right : 0px;
			    	}
			    	
			    	.polaroidcontent {
			    		padding : 150px 150px;
			    		font-family : @font-family-bromello !important;
			    		position : relative;
			    		
			    		h3 {
			    			font-size : 1.5em;
			    			text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			    			text-align : center;
			    			
			    			small {
			    				font-size : 0.75em;
			    			}
			    		}
			    		
			    		.potycontent {
			    			
			    			.potydetails {
			    				text-align : center;
			    				font-family : @font-family-amatic !important;
			    				font-size : 2em;
			    			}
			    		}
			    	}
			    }
			    
			    // Instagram Box
			    .instagrambox {
			    	font-family : @font-family-bromello !important;
			    	color : @white;
			    	
			    	.igbhead {
			    		margin-top : 50px;
			    		border-top : 1px solid @black-trans;
			    		
			    		.igbheadleft {
			    			text-align : center;
			    			
				    		h3 {
								font-size : 3em;
								
								a {
									color : @white;
									
									&:hover {
										text-decoration : none;
										color : @white-trans;
									}
								}
								small {
				    				font-family : @font-family-amatic !important;
				    				font-size : 0.8em;
								}
				    		}	
			    		}
			    		.igbheadright {
			    			text-align : right;
			    			
			    		}
			    	}
			    	.sb_instagram_header {
			    		display : none;
			    	}
			    	.sbi_follow_btn {
			    		
			    		a {
			    			font-size : 1.5em !important;
			    		}
			    	}
			    	.sbi_load_btn {
			    		font-size : 1.5em !important;
			    	}
			    }

				// Art news feed
				.newshomeposts {
					margin-top : 50px;
			    	border-top : 1px solid @black-trans;
			    	font-family : @font-family-amatic !important;
					
					.nhpintrobox {
						background-color : transparent;
						font-family : @font-family-bromello !important;
						color : @white;
						text-align : center;
						
						h3 {
							font-size : 3em;
						}
					}
					
					.nfpcontent {
						
						.nfparticle {
							
							.newswords {
								
								h4 {
									font-family : @font-family-bromello !important;
									font-size : @font-size-h3;
								}
								.newsdatestamp {
									font-size : @font-size-h5 !important;
								}
								.newswordscont {
									font-size : @font-size-large !important;
									
									P {
										font-size : @font-size-large !important;
									}
								}
							}
							
							&.first, &.third {
								background-color : @gray-dark;
								
								.newsimage {
									border-color : @gray-dark;
								}
							}
							&.second, &.fourth {
								background-color : @gray;
								
								.newsimage {
									border-color : @gray;
								}
							}
							
							a {
								display : block;
								
								&:hover {
									text-decoration : none;
									
									.nfpover {
										
										.nfpoverwords {
						    				opacity: 1.0;
						    				filter: alpha(opacity=100);
											background-color : fade(@black, 40%);
										}
									}
								}
							}
						}
					}
				}

			}

		}
	}
}