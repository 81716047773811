// Styles in this sheet override main site styles to apply Sixth form styling on site elements

// SIXTH FORM STYLES
.sixthformlayout {
	
	// BG gradient colour - Content area
	.gradient-contentbgbackground-6F;


// -------------------------------------------
// FROM PageHeadings.less
// Page headers
.page-header-surround {
	.page-header {
		.mpswelcomecontent {
			background-color : @brand-primary-fasfblue-trans !important;
		}
	}
}


// -------------------------------------------
// FROM ContentArea.less
// Back button on Curriculum pages (initially)
.undertitlebackbutton {
	background-color : @brand-primary-fasfblue;
	
	.btn-primary {
		background-color : @brand-primary-fasfblue-light;
		border : 1px solid @brand-primary-fasfblue;
	}
}


// -------------------------------------------
// FROM YearGroup.less
// SECTION BUTTONS
.ypsectionbuttons {
	
	li {
		background-color : @brand-primary-fasfblue-light;

		// Heading
		&:first-child {
			background-color : @brand-primary-fasfblue;
		}

		// buttons		
		a {
			background-color : @brand-primary-fasfblue-light;
			
			&:hover {
				background-color : @brand-primary-fasfblue-lightest-more;
			}
		}

		// alternate button			
		&:nth-child(2n+3) {

			a {
				background-color : @brand-primary-fasfblue-lightish;
				
				&:hover {
					background-color : @brand-primary-fasfblue-lightest-more;
				}
			}
		}
	}
}
// TAB MENU CONTENT BUTTONS
.yptabcontent {
	
	.btnbacktocurric {
		background-color : @brand-primary-fasfblue !important;
	}
	
	li {
		background-color : @brand-primary-fasfblue-light;
		color : @white;
		
		a {
			color : @white;
			
			&:hover {
				background-color : @brand-primary-fasfblue-lightest-more;
			}
		}
		
		&.active {
			background-color : @brand-primary-fasfblue;
			
			a {
				background-color : @brand-primary-fasfblue;
			}
		}
	}
}
// LEARNING MANAGER
.ypmeetlm {
	
	.panel-heading {
		background-color : @brand-primary-fasfblue;
	}
	.panel-body {
		background-color : @brand-primary-fasfblue-lightest-more;
	}
	.panel-footer {
		background-color : @brand-primary-fasfblue;
	}
}


// -------------------------------------------
// FROM ContentArea-SpecialBoxes.less
// EXAM BOARD SUBHEADING ON COURSE PAGES
.examboardinfo {
	background-color : @brand-primary-fasfblue-lightish;
}


// -------------------------------------------
// FROM ContentArea-SpecialBoxes.less
// Pastoral Care / Life in Sixth Form colours
.homespotlightboxes {
	
	.hslb {
		
		a.hslblinkbox {
			color : @brand-primary-fasfblue;
		}
		
		&:hover {
			
    		.hslbbox {
    			background-color : fade(@brand-primary-fasfblue, 50%);
    		}
		}
	}
}



// -------------------------------------------
// NEW STYLES

// Breadcrumb bar
.breadcrumb {
    background-color : @brand-primary-fasfblue-light;
    a {
        color : @brand-primary-fasfblue-light !important;
    }
}

// Teacher links
.teacherlinks {
    .gradient-grey;
    text-align : right;
    
    .innerblock {
        
        ul {
            .zeropm;
            padding-top : 5px !important;
            padding-bottom : 5px !important;
            margin-right : 0px !important;
            
            list-style-type : none;
            
            li {
                display : inline-block;
                
                .btn-sm {
                    font-size : 11px !important;
                    padding : 0px 2px !important;
                    font-weight : bold !important;
                }
            }
        }
    }
}


	
} // END .sixthformlayout

// Other rules
// Top menu - MainNavigation.less