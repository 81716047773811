//
// Variables
// --------------------------------------------------

//== Colors
//
//## Grey and brand colors for use across Bootstrap.

@gray-darker:            lighten(#000, 13.5%); // #222
@gray-dark:              lighten(#000, 20%);   // #333
@gray:                   lighten(#000, 33.5%); // #555
@gray-light:             lighten(#000, 60%);   // #999
@gray-lighter:           lighten(#000, 93.5%); // #eee

//@brand-primary:         #428bca;
@brand-primary:			#2B5235;
@brand-success:         #5cb85c;
@brand-info:            #5bc0de;
@brand-warning:         #f0ad4e;
@brand-danger:          #d9534f;

@white:             #FFFFFF;
@white-lesstrans:	fade(@white, 25%);
@white-trans:		fade(@white, 50%);
@white-moretrans:	fade(@white, 75%);
@black:             #000000;
@black-lesstrans:	fade(@black, 25%);
@black-trans:		fade(@black, 50%);
@black-moretrans:	fade(@black, 15%);

@well-grey:             rgba(245,245,245,1);
@well-grey-trans:       rgba(245,245,245,0.7); 

// Green
@brand-primary-one:         	#2B5235;
@brand-primary-one-dark:    	darken(@brand-primary-one, 10%);
@brand-primary-one-light:   	lighten(@brand-primary-one, 40%);
@brand-primary-one-lightish:	lighten(@brand-primary-one, 20%);
@brand-primary-one-shade:       #2B5235;
@brand-primary-one-lesstrans:	fade(@brand-primary-one, 80%);
@brand-primary-one-trans:		fade(@brand-primary-one, 50%);
@brand-primary-one-moretrans:	fade(@brand-primary-one, 35%);
@brand-primary-one-invis:   	fade(@brand-primary-one, 0%);
@brand-primary-one-shade-trans: fade(@brand-primary-one-shade, 50%);
@brand-primary-one-trans-shade:	#B3C2B3;

// SWS Blue
@brand-primary-two:         	#4c8187;
@brand-primary-two-dark:    	darken(@brand-primary-two, 10%);
@brand-primary-two-darkish:    	darken(@brand-primary-two, 5%);
@brand-primary-two-light:   	lighten(@brand-primary-two, 40%);
@brand-primary-two-lightest:	lighten(@brand-primary-two, 50%);
@brand-primary-two-lightestmore:	lighten(@brand-primary-two, 60%);
@brand-primary-two-trans:		fade(@brand-primary-two, 50%);

// Dark Blue
@brand-primary-three:       	#0B262C;
@brand-primary-three-dark:  	darken(@brand-primary-three, 10%);
@brand-primary-three-light: 	lighten(@brand-primary-three, 40%);
@brand-primary-three-lightest:	lighten(@brand-primary-three, 50%);
@brand-primary-three-lightest-more:	lighten(@brand-primary-three, 60%);
@brand-primary-three-trans:		fade(@brand-primary-three, 50%);

// Light Blue
@brand-primary-four:    		#7EA7AD;
@brand-primary-four-dark:		darken(@brand-primary-four, 10%);
@brand-primary-four-light:		lighten(@brand-primary-four, 40%);
@brand-primary-four-lighter:	lighten(@brand-primary-four, 50%);
@brand-primary-four-lightest:	lighten(@brand-primary-four, 60%);
@brand-primary-four-trans:		fade(@brand-primary-four, 50%);

// Brown
@brand-primary-five:        	#A8A677;
@brand-primary-five-dark:   	darken(@brand-primary-five, 10%);
@brand-primary-five-light:  	lighten(@brand-primary-five, 40%);
@brand-primary-five-trans:		fade(@brand-primary-five, 50%);

// Light Brown
@brand-primary-six:     		#B3B68D;
@brand-primary-six-dark:		darken(@brand-primary-six, 10%);
@brand-primary-six-light:		lighten(@brand-primary-six, 40%);
@brand-primary-six-trans:		fade(@brand-primary-six, 50%);

// FASF Blue
@brand-primary-fasfblue:        #283468;
@brand-primary-fasfblue-dark:   darken(@brand-primary-fasfblue, 10%);
@brand-primary-fasfblue-light:  lighten(@brand-primary-fasfblue, 40%);
@brand-primary-fasfblue-lightish:  lighten(@brand-primary-fasfblue, 50%);
@brand-primary-fasfblue-lightest-more:	lighten(@brand-primary-fasfblue, 60%);
@brand-primary-fasfblue-trans:  fade(@brand-primary-fasfblue, 50%);
@brand-primary-fasfblue-moretrans:	fade(@brand-primary-fasfblue, 35%);
@brand-primary-fasfblue-invis:   	fade(@brand-primary-fasfblue, 0%);

// Re:Play Red
@brand-primary-rpred:        #E5444A;
@brand-primary-rpred-dark:   darken(@brand-primary-rpred, 10%);
@brand-primary-rpred-light:  lighten(@brand-primary-rpred, 40%);
@brand-primary-rpred-trans:  fade(@brand-primary-rpred, 50%);

// Community Colours
@comred:                #D90000;
@comred-dark:           darken(@comred, 10%);
@comred-light:          lighten(@comred, 30%);
@comred-lighter:        lighten(@comred, 50%);
@comgreen:              #238C00;
@comgreen-dark:         darken(@comgreen, 10%);
@comblue:               #0036D9;
@comblue-dark:          darken(@comblue, 10%);
@comyellow:             #FFFF00;
@comyellow-dark:        darken(@comyellow, 10%);


// Gradients

// Content BG background
.gradient-lightgrey-to-white {
    background: rgb(224,224,224); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(224,224,224,1) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(224,224,224,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(224,224,224,1) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0e0e0', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}
.gradient-contentbgbackground {
    background: @brand-primary-one-trans-shade; /* Old browsers */
    background: -moz-linear-gradient(top, @brand-primary-one-trans-shade 0%, @white 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@brand-primary-one-trans-shade), color-stop(100%,@white)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, @brand-primary-one-trans-shade 0%,@white 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, @brand-primary-one-trans-shade 0%,@white 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, @brand-primary-one-trans-shade 0%,@white 100%); /* IE10+ */
    background: linear-gradient(to bottom, @brand-primary-one-trans-shade 0%,@white 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@brand-primary-one-trans-shade', endColorstr='@white',GradientType=0 ); /* IE6-8 */	
}
.gradient-contentbgbackground2 {
    background: @brand-primary-one-trans; /* Old browsers */
    background: -moz-linear-gradient(top, @brand-primary-one-trans 0%, @white 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@brand-primary-one-trans), color-stop(100%,@white)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, @brand-primary-one-trans 0%,@white 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, @brand-primary-one-trans 0%,@white 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, @brand-primary-one-trans 0%,@white 100%); /* IE10+ */
    background: linear-gradient(to bottom, @brand-primary-one-trans 0%,@white 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@brand-primary-one-lightish', endColorstr='@white',GradientType=0 ); /* IE6-8 */	
}
.gradient-contentbgbackground-6F {
    background: @brand-primary-fasfblue-trans; /* Old browsers */
    background: -moz-linear-gradient(top, @brand-primary-fasfblue-trans 0%, @white 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@brand-primary-fasfblue-trans), color-stop(100%,@white)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, @brand-primary-fasfblue-trans 0%,@white 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, @brand-primary-fasfblue-trans 0%,@white 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, @brand-primary-fasfblue-trans 0%,@white 100%); /* IE10+ */
    background: linear-gradient(to bottom, @brand-primary-fasfblue-trans 0%,@white 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@brand-primary-fasfblue-lightish', endColorstr='@white',GradientType=0 ); /* IE6-8 */	
}
// Footer background
.gradient-greytopurple {
    background: @brand-primary-one; /* Old browsers */
    background: -moz-linear-gradient(top, @gray-light 0%, @brand-primary-one 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@gray-light), color-stop(100%,@brand-primary-one)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, @gray-light 0%,@brand-primary-one 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, @gray-light 0%,@brand-primary-one 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, @gray-light 0%,@brand-primary-one 100%); /* IE10+ */
    background: linear-gradient(to bottom, @gray-light 0%,@brand-primary-one 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@gray-light', endColorstr='@brand-primary-one',GradientType=0 ); /* IE6-8 */	
}
// BGTransOverlay background
.gradient-bgtransoverlay {
    background: @brand-primary-one; /* Old browsers */
    background: -moz-linear-gradient(top, @brand-primary-one-invis 0%, @brand-primary-one 80%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@brand-primary-one-invis), color-stop(80%,@brand-primary-one)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, @brand-primary-one-invis 0%,@brand-primary-one 80%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, @brand-primary-one-invis 0%,@brand-primary-one 80%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, @brand-primary-one-invis 0%,@brand-primary-one 80%); /* IE10+ */
    background: linear-gradient(to bottom, @brand-primary-one-invis 0%,@brand-primary-one 80%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@brand-primary-one-invis', endColorstr='@brand-primary-one',GradientType=0 ); /* IE6-8 */	
}
// 
.gradient-whitetransINV {
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjUiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,0.5))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#80ffffff',GradientType=0 ); /* IE6-8 */
}
.gradient-BGimageshade {
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.01) 0%, rgba(0,0,0,0.01) 1%, rgba(0,0,0,0.2) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.01) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.2) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0.01) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#03000000', endColorstr='#33000000',GradientType=0 ); /* IE6-9 */
}

// Old from here
.gradient-homenotice {
    background: #e3eeef; /* Old browsers */
    background: -moz-linear-gradient(top,  #c2dadd 1%, #e3eeef 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #c2dadd 1%, #e3eeef 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #c2dadd 1%, #e3eeef 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c2dadd', endColorstr='#e3eeef',GradientType=0 ); /* IE6-9 */    
}
.gradient-fasfblue {
    background: rgb(40,52,104); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(40,52,104,1) 0%, rgba(73,85,147,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(40,52,104,1)), color-stop(100%,rgba(73,85,147,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(40,52,104,1) 0%,rgba(73,85,147,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(40,52,104,1) 0%,rgba(73,85,147,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(40,52,104,1) 0%,rgba(73,85,147,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(40,52,104,1) 0%,rgba(73,85,147,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#283468', endColorstr='#495593',GradientType=0 ); /* IE6-8 */
}
.gradient-fasflightblue {
    background: rgb(138,152,210); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(138,152,210,1) 0%, rgba(155,179,232,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(138,152,210,1)), color-stop(100%,rgba(155,179,232,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(138,152,210,1) 0%,rgba(155,179,232,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(138,152,210,1) 0%,rgba(155,179,232,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(138,152,210,1) 0%,rgba(155,179,232,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(138,152,210,1) 0%,rgba(155,179,232,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8a98d2', endColorstr='#9bb3e8',GradientType=0 ); /* IE6-9 */
}
.gradient-green {
    background: #2b5235; /* Old browsers */
    background: -moz-linear-gradient(top, #2b5235 0%, #427a4f 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#2b5235), color-stop(100%,#427a4f)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #2b5235 0%,#427a4f 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #2b5235 0%,#427a4f 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #2b5235 0%,#427a4f 100%); /* IE10+ */
    background: linear-gradient(to bottom, #2b5235 0%,#427a4f 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2b5235', endColorstr='#427a4f',GradientType=0 ); /* IE6-9 */      
}
.gradient-swsblue {
    background: #4c8187; /* Old browsers */
    background: -moz-linear-gradient(top, #4c8187 0%, #76babf 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#4c8187), color-stop(100%,#76babf)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #4c8187 0%,#76babf 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #4c8187 0%,#76babf 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #4c8187 0%,#76babf 100%); /* IE10+ */
    background: linear-gradient(to bottom, #4c8187 0%,#76babf 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c8187', endColorstr='#76babf',GradientType=0 ); /* IE6-9 */   
}
.gradient-darkblue {
    background: #0b262c; /* Old browsers */
    background: -moz-linear-gradient(top, #0b262c 0%, #18525e 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#0b262c), color-stop(100%,#18525e)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #0b262c 0%,#18525e 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #0b262c 0%,#18525e 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #0b262c 0%,#18525e 100%); /* IE10+ */
    background: linear-gradient(to bottom, #0b262c 0%,#18525e 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0b262c', endColorstr='#18525e',GradientType=0 ); /* IE6-9 */    
}
.gradient-lightblue {
    background: #7ea7ad; /* Old browsers */
    background: -moz-linear-gradient(top, #7ea7ad 0%, #98cad1 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#7ea7ad), color-stop(100%,#98cad1)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #7ea7ad 0%,#98cad1 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #7ea7ad 0%,#98cad1 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #7ea7ad 0%,#98cad1 100%); /* IE10+ */
    background: linear-gradient(to bottom, #7ea7ad 0%,#98cad1 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7ea7ad', endColorstr='#98cad1',GradientType=0 ); /* IE6-9 */
}
.gradient-brown {
    background: #a8a677; /* Old browsers */
    background: -moz-linear-gradient(top, #a8a677 0%, #d1ce94 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#a8a677), color-stop(100%,#d1ce94)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #a8a677 0%,#d1ce94 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #a8a677 0%,#d1ce94 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #a8a677 0%,#d1ce94 100%); /* IE10+ */
    background: linear-gradient(to bottom, #a8a677 0%,#d1ce94 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a8a677', endColorstr='#d1ce94',GradientType=0 ); /* IE6-9 */    
}
.gradient-lightbrown {
    background: #b3b68d; /* Old browsers */
    background: -moz-linear-gradient(top, #b3b68d 0%, #dfe2b1 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#b3b68d), color-stop(100%,#dfe2b1)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #b3b68d 0%,#dfe2b1 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #b3b68d 0%,#dfe2b1 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #b3b68d 0%,#dfe2b1 100%); /* IE10+ */
    background: linear-gradient(to bottom, #b3b68d 0%,#dfe2b1 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3b68d', endColorstr='#dfe2b1',GradientType=0 ); /* IE6-9 */
}
.gradient-whitetrans {
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjUiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(top,  rgba(255,255,255,0.5) 0%, rgba(255,255,255,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-8 */
}
.gradient-lightgrey {
    background: rgb(230,230,230); /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U2ZTZlNiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(230,230,230,1)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(230,230,230,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(230,230,230,1) 0%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(230,230,230,1) 0%,rgba(255,255,255,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(230,230,230,1) 0%,rgba(255,255,255,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6e6e6', endColorstr='#ffffff',GradientType=0 ); /* IE6-8 */    
}
.gradient-blue {  
    background: #4787c2; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIxJSIgc3RvcC1jb2xvcj0iIzQ3ODdjMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM2ZmEwY2UiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #4787c2 1%, #6fa0ce 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(1%,#4787c2), color-stop(100%,#6fa0ce)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #4787c2 1%,#6fa0ce 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #4787c2 1%,#6fa0ce 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #4787c2 1%,#6fa0ce 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #4787c2 1%,#6fa0ce 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4787c2', endColorstr='#6fa0ce',GradientType=0 ); /* IE6-8 */
}
.gradient-red {
   background: #eb1646; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ViMTY0NiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlZDNjNjQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #eb1646 0%, #ed3c64 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#eb1646), color-stop(100%,#ed3c64)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #eb1646 0%,#ed3c64 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #eb1646 0%,#ed3c64 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #eb1646 0%,#ed3c64 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #eb1646 0%,#ed3c64 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb1646', endColorstr='#ed3c64',GradientType=0 ); /* IE6-8 */
}
.gradient-grey {
    background: #98999c; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIzMyUiIHN0b3AtY29sb3I9IiM5ODk5OWMiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjYWFhYmFlIiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
    background: -moz-linear-gradient(top,  #98999c 33%, #aaabae 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(33%,#98999c), color-stop(100%,#aaabae)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #98999c 33%,#aaabae 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #98999c 33%,#aaabae 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #98999c 33%,#aaabae 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #98999c 33%,#aaabae 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#98999c', endColorstr='#aaabae',GradientType=0 ); /* IE6-8 */   
}
.gradient-blacksubtle {
    background: rgb(71,71,71); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(71,71,71,1) 37%, rgba(0,0,0,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(37%,rgba(71,71,71,1)), color-stop(100%,rgba(0,0,0,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(71,71,71,1) 37%,rgba(0,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(71,71,71,1) 37%,rgba(0,0,0,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(71,71,71,1) 37%,rgba(0,0,0,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(71,71,71,1) 37%,rgba(0,0,0,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#474747', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}
.gradient-rpred {
    background: rgb(226,115,119); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(226,115,119,1) 32%, rgba(229,68,74,1) 99%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(32%,rgba(226,115,119,1)), color-stop(99%,rgba(229,68,74,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(226,115,119,1) 32%,rgba(229,68,74,1) 99%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(226,115,119,1) 32%,rgba(229,68,74,1) 99%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(226,115,119,1) 32%,rgba(229,68,74,1) 99%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(226,115,119,1) 32%,rgba(229,68,74,1) 99%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e27377', endColorstr='#e5444a',GradientType=0 ); /* IE6-9 */
}

//== Scaffolding
//
// ## Settings for some of the most global styles.

// Background color for `<body>`.
@body-bg:               #fff;
//** Global text color on `<body>`.
@text-color:            @gray-dark;

//** Global textual link color.
@link-color:            @brand-primary;
//** Link hover color set via `darken()` function.
@link-hover-color:      darken(@link-color, 15%);


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

@font-family-sans-serif:  @MuseoSans300, Open Sans, sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;
@font-family-bebas:       BebasNeueBook, "Open Sans", sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-bebas-bold:  BebasNeueBold, BebasNeueBook, "Open Sans", sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-almagro:     Almagro, @font-family-sans-serif;
@font-family-amatic:      amatic_scregular, @font-family-sans-serif;
@font-family-amatic-bold: amatic_scbold, @font-family-sans-serif;
//@font-family-bromello:    amatic_scregular, @font-family-sans-serif;
@font-family-bromello:    bromello, @font-family-sans-serif;

@font-size-base:          16px;
@font-size-large:         ceil((@font-size-base * 1.25)); // ~18px
@font-size-small:         ceil((@font-size-base * 0.85)); // ~12px
@font-size-smaller:         ceil((@font-size-base * 0.70));
@font-size-amatic:        ceil((@font-size-base * 2.50));
@font-size-bromello:      ceil((@font-size-base * 2.50));

@font-size-h1:            floor((@font-size-base * 2.6)); // ~36px
@font-size-h2:            floor((@font-size-base * 2.15)); // ~30px
@font-size-h3:            ceil((@font-size-base * 1.7)); // ~24px
@font-size-h4:            ceil((@font-size-base * 1.25)); // ~18px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
@line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
@headings-font-family:    inherit;
@headings-font-weight:    500;
@headings-line-height:    1.1;
@headings-color:          inherit;


//-- Iconography
//
//## Specify custom locations of the include Glyphicons icon font. Useful for those including Bootstrap via Bower.

@icon-font-path:          "../fonts/";
@icon-font-name:          "glyphicons-halflings-regular";
@icon-font-svg-id:        "glyphicons_halflingsregular";

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

@padding-base-vertical:     6px;
@padding-base-horizontal:   12px;

@padding-large-vertical:    10px;
@padding-large-horizontal:  16px;

@padding-small-vertical:    5px;
@padding-small-horizontal:  10px;

@padding-xs-vertical:       1px;
@padding-xs-horizontal:     5px;

@line-height-large:         1.33;
@line-height-small:         1.5;

@border-radius-base:        4px;
@border-radius-large:       6px;
@border-radius-small:       3px;

//** Global color for active items (e.g., navs or dropdowns).
@component-active-color:    #fff;
//** Global background color for active items (e.g., navs or dropdowns).
@component-active-bg:       @brand-primary;

//** Width of the `border` for generating carets that indicator dropdowns.
@caret-width-base:          4px;
//** Carets increase slightly in size for larger components.
@caret-width-large:         5px;


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
@table-cell-padding:            8px;
//** Padding for cells in `.table-condensed`.
@table-condensed-cell-padding:  5px;

//** Default background color used for all tables.
@table-bg:                      transparent;
//** Background color used for `.table-striped`.
@table-bg-accent:               #f9f9f9;
//** Background color used for `.table-hover`.
@table-bg-hover:                #f5f5f5;
@table-bg-active:               @table-bg-hover;

//** Border color for table and cell borders.
@table-border-color:            #ddd;


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

@btn-font-weight:                normal;

@btn-default-color:              #333;
@btn-default-bg:                 #fff;
@btn-default-border:             #ccc;

@btn-primary-color:              #fff;
@btn-primary-bg:                 @brand-primary-two;
@btn-primary-border:             darken(@btn-primary-bg, 5%);

@btn-success-color:              #fff;
@btn-success-bg:                 @brand-success;
@btn-success-border:             darken(@btn-success-bg, 5%);

@btn-info-color:                 #fff;
@btn-info-bg:                    @brand-info;
@btn-info-border:                darken(@btn-info-bg, 5%);

@btn-warning-color:              #fff;
@btn-warning-bg:                 @brand-warning;
@btn-warning-border:             darken(@btn-warning-bg, 5%);

@btn-danger-color:               #fff;
@btn-danger-bg:                  @brand-danger;
@btn-danger-border:              darken(@btn-danger-bg, 5%);

@btn-link-disabled-color:        @gray-light;


//== Forms
//
//##

//** `<input>` background color
@input-bg:                       #fff;

//** `<input disabled>` background color
@input-bg-disabled:              @gray-lighter;

//** Text color for `<input>`s
@input-color:                    @gray;
//** `<input>` border color
@input-border:                   #ccc;
//** `<input>` border radius
@input-border-radius:            @border-radius-base;
//** Border color for inputs on focus
@input-border-focus:             #66afe9;

//** Placeholder text color
@input-color-placeholder:        @gray-light;

//** Default `.form-control` height
@input-height-base:              (@line-height-computed + (@padding-base-vertical * 2) + 2);
//** Large `.form-control` height
@input-height-large:             (ceil(@font-size-large * @line-height-large) + (@padding-large-vertical * 2) + 2);
//** Small `.form-control` height
@input-height-small:             (floor(@font-size-small * @line-height-small) + (@padding-small-vertical * 2) + 2);

@legend-color:                   @gray-dark;
@legend-border-color:            #e5e5e5;

//** Background color for textual input addons
@input-group-addon-bg:           @gray-lighter;
//** Border color for textual input addons
@input-group-addon-border-color: @input-border;


//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
@dropdown-bg:                    #fff;
//** Dropdown menu `border-color`.
@dropdown-border:                rgba(0,0,0,.15);
//** Dropdown menu `border-color` **for IE8**.
@dropdown-fallback-border:       #ccc;
//** Divider color for between dropdown items.
@dropdown-divider-bg:            #e5e5e5;

//** Dropdown link text color.
@dropdown-link-color:            @gray-dark;
//** Hover color for dropdown links.
@dropdown-link-hover-color:      darken(@gray-dark, 5%);
//** Hover background for dropdown links.
@dropdown-link-hover-bg:         #f5f5f5;

//** Active dropdown menu item text color.
@dropdown-link-active-color:     @component-active-color;
//** Active dropdown menu item background color.
@dropdown-link-active-bg:        @component-active-bg;

//** Disabled dropdown menu item background color.
@dropdown-link-disabled-color:   @gray-light;

//** Text color for headers within dropdown menus.
@dropdown-header-color:          @gray-light;

// Note: Deprecated @dropdown-caret-color as of v3.1.0
@dropdown-caret-color:           #000;


//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

@zindex-navbar:            1000;
@zindex-dropdown:          1000;
@zindex-popover:           1010;
@zindex-tooltip:           1030;
@zindex-navbar-fixed:      1030;
@zindex-modal-background:  1040;
@zindex-modal:             1050;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
// Note: Deprecated @screen-xs and @screen-phone as of v3.0.1
@screen-xs:                  480px;
@screen-xs-min:              @screen-xs;
@screen-phone:               @screen-xs-min;

// Small screen / tablet
// Note: Deprecated @screen-sm and @screen-tablet as of v3.0.1
//@screen-sm:                  768px;
@screen-sm:                  788px; // GE - Edited to fix dodgy iPad Landscape layout issue
@screen-sm-min:              @screen-sm;
@screen-tablet:              @screen-sm-min;

// Medium screen / desktop
// Note: Deprecated @screen-md and @screen-desktop as of v3.0.1
@screen-md:                  992px;
@screen-md-min:              @screen-md;
@screen-desktop:             @screen-md-min;

// Large screen / wide desktop
// Note: Deprecated @screen-lg and @screen-lg-desktop as of v3.0.1
@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
@screen-lg-desktop:          @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
@grid-columns:              12;
//** Padding between columns. Gets divided in half for the left and right.
@grid-gutter-width:         30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
@grid-float-breakpoint:     @screen-sm-min;
//** Point at which the navbar begins collapsing.
@grid-float-breakpoint-max: (@grid-float-breakpoint - 1);


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
@container-tablet:             ((720px + @grid-gutter-width));
//** For `@screen-sm-min` and up.
@container-sm:                 @container-tablet;

// Medium screen / desktop
@container-desktop:            ((940px + @grid-gutter-width));
//** For `@screen-md-min` and up.
@container-md:                 @container-desktop;

// Large screen / wide desktop
@container-large-desktop:      ((1140px + @grid-gutter-width));
//** For `@screen-lg-min` and up.
@container-lg:                 @container-large-desktop;


//== Navbar
//
//##

// Basics of a navbar
@navbar-height:                    50px;
@navbar-margin-bottom:             @line-height-computed;
@navbar-border-radius:             @border-radius-base;
@navbar-padding-horizontal:        floor((@grid-gutter-width / 2));
@navbar-padding-vertical:          ((@navbar-height - @line-height-computed) / 2);
@navbar-collapse-max-height:       700px;//340px;

@navbar-default-color:             #777;
@navbar-default-bg:                #f8f8f8;
@navbar-default-border:            darken(@navbar-default-bg, 6.5%);

// Navbar links
@navbar-default-link-color:                #777;
@navbar-default-link-hover-color:          #333;
@navbar-default-link-hover-bg:             transparent;
@navbar-default-link-active-color:         #555;
@navbar-default-link-active-bg:            darken(@navbar-default-bg, 6.5%);
@navbar-default-link-disabled-color:       #ccc;
@navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
@navbar-default-brand-color:               @navbar-default-link-color;
@navbar-default-brand-hover-color:         darken(@navbar-default-brand-color, 10%);
@navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
@navbar-default-toggle-hover-bg:           #ddd;
@navbar-default-toggle-icon-bar-bg:        #888;
@navbar-default-toggle-border-color:       #ddd;


// Inverted navbar
// Reset inverted navbar basics
@navbar-inverse-color:                      @gray-light;
@navbar-inverse-bg:                         #222;
@navbar-inverse-border:                     darken(@navbar-inverse-bg, 10%);

// Inverted navbar links
@navbar-inverse-link-color:                 @gray-light;
@navbar-inverse-link-hover-color:           #fff;
@navbar-inverse-link-hover-bg:              transparent;
@navbar-inverse-link-active-color:          @navbar-inverse-link-hover-color;
@navbar-inverse-link-active-bg:             darken(@navbar-inverse-bg, 10%);
@navbar-inverse-link-disabled-color:        #444;
@navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
@navbar-inverse-brand-color:                @navbar-inverse-link-color;
@navbar-inverse-brand-hover-color:          #fff;
@navbar-inverse-brand-hover-bg:             transparent;

// Inverted navbar toggle
@navbar-inverse-toggle-hover-bg:            #333;
@navbar-inverse-toggle-icon-bar-bg:         #fff;
@navbar-inverse-toggle-border-color:        #333;


//== Navs
//
//##

//=== Shared nav styles
@nav-link-padding:                          10px 15px;
@nav-link-hover-bg:                         @gray-lighter;

@nav-disabled-link-color:                   @gray-light;
@nav-disabled-link-hover-color:             @gray-light;

@nav-open-link-hover-color:                 #fff;

//== Tabs
@nav-tabs-border-color:                     #ddd;

@nav-tabs-link-hover-border-color:          @gray-lighter;

@nav-tabs-active-link-hover-bg:             @body-bg;
@nav-tabs-active-link-hover-color:          @gray;
@nav-tabs-active-link-hover-border-color:   #ddd;

@nav-tabs-justified-link-border-color:            #ddd;
@nav-tabs-justified-active-link-border-color:     @body-bg;

//== Pills
@nav-pills-border-radius:                   @border-radius-base;
@nav-pills-active-link-hover-bg:            @component-active-bg;
@nav-pills-active-link-hover-color:         @component-active-color;


//== Pagination
//
//##

@pagination-color:                     @link-color;
@pagination-bg:                        #fff;
@pagination-border:                    #ddd;

@pagination-hover-color:               @link-hover-color;
@pagination-hover-bg:                  @gray-lighter;
@pagination-hover-border:              #ddd;

@pagination-active-color:              #fff;
@pagination-active-bg:                 @brand-primary;
@pagination-active-border:             @brand-primary;

@pagination-disabled-color:            @gray-light;
@pagination-disabled-bg:               #fff;
@pagination-disabled-border:           #ddd;


//== Pager
//
//##

@pager-bg:                             @pagination-bg;
@pager-border:                         @pagination-border;
@pager-border-radius:                  15px;

@pager-hover-bg:                       @pagination-hover-bg;

@pager-active-bg:                      @pagination-active-bg;
@pager-active-color:                   @pagination-active-color;

@pager-disabled-color:                 @pagination-disabled-color;


//== Jumbotron
//
//##

@jumbotron-padding:              30px;
@jumbotron-color:                inherit;
@jumbotron-bg:                   @gray-lighter;
@jumbotron-heading-color:        inherit;
@jumbotron-font-size:            ceil((@font-size-base * 1.5));


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

@state-success-text:             #3c763d;
@state-success-bg:               #dff0d8;
@state-success-border:           darken(spin(@state-success-bg, -10), 5%);

@state-info-text:                #31708f;
@state-info-bg:                  #d9edf7;
@state-info-border:              darken(spin(@state-info-bg, -10), 7%);

@state-warning-text:             #8a6d3b;
@state-warning-bg:               #fcf8e3;
@state-warning-border:           darken(spin(@state-warning-bg, -10), 5%);

@state-danger-text:              #a94442;
@state-danger-bg:                #f2dede;
@state-danger-border:            darken(spin(@state-danger-bg, -10), 5%);


//== Tooltips
//
//##

//** Tooltip max width
@tooltip-max-width:           200px;
//** Tooltip text color
@tooltip-color:               #fff;
//** Tooltip background color
@tooltip-bg:                  #000;
@tooltip-opacity:             .9;

//** Tooltip arrow width
@tooltip-arrow-width:         5px;
//** Tooltip arrow color
@tooltip-arrow-color:         @tooltip-bg;


//== Popovers
//
//##

//** Popover body background color
@popover-bg:                          #fff;
//** Popover maximum width
@popover-max-width:                   276px;
//** Popover border color
@popover-border-color:                rgba(0,0,0,.2);
//** Popover fallback border color
@popover-fallback-border-color:       #ccc;

//** Popover title background color
@popover-title-bg:                    darken(@popover-bg, 3%);

//** Popover arrow width
@popover-arrow-width:                 10px;
//** Popover arrow color
@popover-arrow-color:                 #fff;

//** Popover outer arrow width
@popover-arrow-outer-width:           (@popover-arrow-width + 1);
//** Popover outer arrow color
@popover-arrow-outer-color:           fadein(@popover-border-color, 5%);
//** Popover outer arrow fallback color
@popover-arrow-outer-fallback-color:  darken(@popover-fallback-border-color, 20%);


//== Labels
//
//##

//** Default label background color
@label-default-bg:            @gray-light;
//** Primary label background color
@label-primary-bg:            @brand-primary;
//** Success label background color
@label-success-bg:            @brand-success;
//** Info label background color
@label-info-bg:               @brand-info;
//** Warning label background color
@label-warning-bg:            @brand-warning;
//** Danger label background color
@label-danger-bg:             @brand-danger;

//** Default label text color
@label-color:                 #fff;
//** Default text color of a linked label
@label-link-hover-color:      #fff;


//== Modals
//
//##

//** Padding applied to the modal body
@modal-inner-padding:         20px;

//** Padding applied to the modal title
@modal-title-padding:         15px;
//** Modal title line-height
@modal-title-line-height:     @line-height-base;

//** Background color of modal content area
@modal-content-bg:                             #fff;
//** Modal content border color
@modal-content-border-color:                   rgba(0,0,0,.2);
//** Modal content border color **for IE8**
@modal-content-fallback-border-color:          #999;

//** Modal backdrop background color
@modal-backdrop-bg:           #000;
//** Modal backdrop opacity
@modal-backdrop-opacity:      .5;
//** Modal header border color
@modal-header-border-color:   #e5e5e5;
//** Modal footer border color
@modal-footer-border-color:   @modal-header-border-color;

@modal-lg:                    900px;
@modal-md:                    600px;
@modal-sm:                    300px;


//== Alerts
//
//## Define alert colors, border radius, and padding.

@alert-padding:               15px;
@alert-border-radius:         @border-radius-base;
@alert-link-font-weight:      bold;

@alert-success-bg:            @state-success-bg;
@alert-success-text:          @state-success-text;
@alert-success-border:        @state-success-border;

@alert-info-bg:               @state-info-bg;
@alert-info-text:             @state-info-text;
@alert-info-border:           @state-info-border;

@alert-warning-bg:            @state-warning-bg;
@alert-warning-text:          @state-warning-text;
@alert-warning-border:        @state-warning-border;

@alert-danger-bg:             @state-danger-bg;
@alert-danger-text:           @state-danger-text;
@alert-danger-border:         @state-danger-border;


//== Progress bars
//
//##

//** Background color of the whole progress component
@progress-bg:                 #f5f5f5;
//** Progress bar text color
@progress-bar-color:          #fff;

//** Default progress bar color
@progress-bar-bg:             @brand-primary;
//** Success progress bar color
@progress-bar-success-bg:     @brand-success;
//** Warning progress bar color
@progress-bar-warning-bg:     @brand-warning;
//** Danger progress bar color
@progress-bar-danger-bg:      @brand-danger;
//** Info progress bar color
@progress-bar-info-bg:        @brand-info;


//== List group
//
//##

//** Background color on `.list-group-item`
@list-group-bg:                 #fff;
//** `.list-group-item` border color
@list-group-border:             #ddd;
//** List group border radius
@list-group-border-radius:      @border-radius-base;

//** Background color of single list elements on hover
@list-group-hover-bg:           #f5f5f5;
//** Text color of active list elements
@list-group-active-color:       @component-active-color;
//** Background color of active list elements
@list-group-active-bg:          @component-active-bg;
//** Border color of active list elements
@list-group-active-border:      @list-group-active-bg;
@list-group-active-text-color:  lighten(@list-group-active-bg, 40%);

@list-group-link-color:         #555;
@list-group-link-heading-color: #333;


//== Panels
//
//##

@panel-bg:                    #fff;
@panel-body-padding:          15px;
@panel-border-radius:         @border-radius-base;

//** Border color for elements within panels
@panel-inner-border:          #ddd;
@panel-footer-bg:             #f5f5f5;

@panel-default-text:          @gray-dark;
@panel-default-border:        #ddd;
@panel-default-heading-bg:    #f5f5f5;

@panel-primary-text:          #fff;
@panel-primary-border:        @brand-primary;
@panel-primary-heading-bg:    @brand-primary;

@panel-success-text:          @state-success-text;
@panel-success-border:        @state-success-border;
@panel-success-heading-bg:    @state-success-bg;

@panel-info-text:             @state-info-text;
@panel-info-border:           @state-info-border;
@panel-info-heading-bg:       @state-info-bg;

@panel-warning-text:          @state-warning-text;
@panel-warning-border:        @state-warning-border;
@panel-warning-heading-bg:    @state-warning-bg;

@panel-danger-text:           @state-danger-text;
@panel-danger-border:         @state-danger-border;
@panel-danger-heading-bg:     @state-danger-bg;


//== Thumbnails
//
//##

//** Padding around the thumbnail image
@thumbnail-padding:           4px;
//** Thumbnail background color
@thumbnail-bg:                @body-bg;
//** Thumbnail border color
@thumbnail-border:            #ddd;
//** Thumbnail border radius
@thumbnail-border-radius:     @border-radius-base;

//** Custom text color for thumbnail captions
@thumbnail-caption-color:     @text-color;
//** Padding around the thumbnail caption
@thumbnail-caption-padding:   9px;


//== Wells
//
//##

@well-bg:                     #f5f5f5;
@well-border:                 darken(@well-bg, 7%);


//== Badges
//
//##

@badge-color:                 #fff;
//** Linked badge text color on hover
@badge-link-hover-color:      #fff;
@badge-bg:                    @gray-light;

//** Badge text color in active nav link
@badge-active-color:          @link-color;
//** Badge background color in active nav link
@badge-active-bg:             #fff;

@badge-font-weight:           bold;
@badge-line-height:           1;
@badge-border-radius:         10px;


//== Breadcrumbs
//
//##

@breadcrumb-padding-vertical:   8px;
@breadcrumb-padding-horizontal: 15px;
//** Breadcrumb background color
@breadcrumb-bg:                 #f5f5f5;
//** Breadcrumb text color
@breadcrumb-color:              #ccc;
//** Text color of current page in the breadcrumb
@breadcrumb-active-color:       @gray-light;
//** Textual separator for between breadcrumb elements
@breadcrumb-separator:          "/";


//== Carousel
//
//##

@carousel-text-shadow:                        0 1px 2px rgba(0,0,0,.6);

@carousel-control-color:                      #fff;
@carousel-control-width:                      15%;
@carousel-control-opacity:                    .5;
@carousel-control-font-size:                  20px;

@carousel-indicator-active-bg:                #fff;
@carousel-indicator-border-color:             #fff;

@carousel-caption-color:                      #fff;


//== Close
//
//##

@close-font-weight:           bold;
@close-color:                 #000;
@close-text-shadow:           0 1px 0 #fff;


//== Code
//
//##

@code-color:                  #c7254e;
@code-bg:                     #f9f2f4;

@kbd-color:                   #fff;
@kbd-bg:                      #333;

@pre-bg:                      #f5f5f5;
@pre-color:                   @gray-dark;
@pre-border-color:            #ccc;
@pre-scrollable-max-height:   340px;


//== Type
//
//##

//** Text muted color
@text-muted:                  @gray-light;
//** Abbreviations and acronyms border color
@abbr-border-color:           @gray-light;
//** Headings small color
@headings-small-color:        @gray-light;
//** Blockquote small color
@blockquote-small-color:      @gray-light;
//** Blockquote font size
@blockquote-font-size:        (@font-size-base * 1.25);
//** Blockquote border color
@blockquote-border-color:     @gray-lighter;
//** Page header border color
@page-header-border-color:    @gray-lighter;


//== Miscellaneous
//
//##

//** Horizontal line color.
@hr-border:                   @gray-lighter;

//** Horizontal offset for forms and lists.
@component-offset-horizontal: 180px;


// Box curves
.top-curved {
    -webkit-border-top-left-radius: 3px;
    -moz-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -moz-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
}
.bottom-curved {
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -webkit-border-bottom-right-radius: 3px;
    -moz-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.box-curved-3 {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
 
// Transition values
.basictransition {
    -webkit-transition: all 0.1s ease-in-out 0.1s;  
    -moz-transition: all 0.1s ease-in-out 0.1s;  
    -ms-transition: all 0.1s ease-in-out 0.1s; 
    -o-transition: all 0.1s ease-in-out 0.1s; 
    transition: all 0.1s ease-in-out 0.1s;    
}
.transition-header-zeropointone {
	-webkit-transition: all 0.1s ease-in-out 0.1s;
	-moz-transition: all 0.1s ease-in-out 0.1s;
	-ms-transition: all 0.1s ease-in-out 0.1s;
	-o-transition: all 0.1s ease-in-out 0.1s;
	transition: all 0.1s ease-in-out 0.1s;
}
.transition-header-zeropointtwofive {
	-webkit-transition: all 0.25s ease-in-out 0.25s;
	-moz-transition: all 0.25s ease-in-out 0.25s;
	-ms-transition: all 0.25s ease-in-out 0.25s;
	-o-transition: all 0.25s ease-in-out 0.25s;
	transition: all 0.25s ease-in-out 0.25s;
}
.transition-header {
	transition : all 0.5s ease-in-out 0.1s;
	-webkit-transition : all 0.5s ease-in-out 0.1s;
	-moz-transition : all 0.5s ease-in-out 0.1s;	
	
	-webkit-transition: all 0.5s ease-in-out 0.1s;
	-moz-transition: all 0.5s ease-in-out 0.1s;
	-ms-transition: all 0.5s ease-in-out 0.1s;
	-o-transition: all 0.5s ease-in-out 0.1s;
	transition: all 0.5s ease-in-out 0.1s;
}

// Border Radius
.border-zero {
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
}
.border-two {
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
}
.border-four {
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

// Columns for dropdowns
.doublecolumns {
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
	-webkit-column-gap: 0px;
	-moz-column-gap: 0px;
	column-gap: 0px;
}
.singlecolumns {
	-webkit-column-count: 1;
	-moz-column-count: 1;
	column-count: 1;
	-webkit-column-gap: 0px;
	-moz-column-gap: 0px;
	column-gap: 0px;
}