// KO colours
@cmred:                #D90000;
@cmred-dark:           darken(@cmred, 10%);
@cmred-light:          lighten(@cmred, 30%);
@cmred-lighter:        lighten(@cmred, 50%);

@cmgreen:              #238C00;
@cmgreen-dark:         darken(@cmgreen, 10%);
@cmgreen-light:        lighten(@cmgreen, 30%);

@cmblue:               #0036D9;
@cmblue-dark:          darken(@cmblue, 10%);
@cmblue-light:         lighten(@cmblue, 30%);

@cmyellow:             #FFFF00;
@cmyellow-dark:        darken(@cmyellow, 10%);
@cmyellow-light:       lighten(@cmyellow, 30%);

.cmtable {
	width : 100%;
	
	margin : 20px 0px;
	padding : 30px 10px;
	background-color : @brand-primary-two-lightest;
	border-collapse : separate;
	border-spacing : 40px 0px;
	
	-webkit-box-shadow : 0 0px 1px;
	box-shadow : 0 0px 1px;
	
	font-size : @font-size-smaller;
	line-height : @font-size-smaller;
	font-weight : bold;

	.spacer {
		
		.spacerline {
			margin : 30px 0px;
			border-bottom : 2px solid @brand-primary-two
		}
	}
	
	th {
		border : 2px solid @brand-primary-two-dark;
		background-color : @brand-primary-two;
		color : @white;
		border-collapse : collapse;
		
		.cmyeartitle {
			-moz-transform: rotate(-90.0deg);
			-o-transform: rotate(-90.0deg);
			-webkit-transform: rotate(-90.0deg);
			//filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.083);
			-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)";
			transform: rotate(-90.0deg);
			
			border : 1px solid @brand-primary-two;
			white-space : nowrap;
			font-size : @font-size-small;
			text-align : center;
		}	
	}

	td {
		//width : 20%;
		
		.cmtdinner {
			padding : 18px 10px 10px 10px;
			text-align : center;
			
			p {
				margin-bottom : 8px;
				font-size : @font-size-smaller;
				line-height : @font-size-smaller;
			}
		}
		
		&.cmname {
			border : 2px solid @black-trans;
			background-color : @white;
			//color : @white;
			
			.cmtdinner {
					
			}
		}
		
		&.cmskill {
			border : 2px solid @cmred;
			background-color : @cmred-light;
			color : @white;
			
			.cmtdinner {
				
			}
		}
		
		&.cmdetails {
			border : 2px solid @cmblue;
			background-color : @cmblue-light;
			color : @white;	
			
			.cmtdinner {
				
			}		
		}
	}
	
	.cmy9 {
		
	}
	
	.cmy8 {
		
	}
	
	.cmy7 {
		
	}
}


// NEW REFINED VERSION

.kobutton {
	padding : 10px 10px;
	font-size : 12px;
	//line-height : 18px !important;
	line-height : 50px !important;
	font-weight : 700;
	white-space : nowrap;
}
p.kobutton {
	border : 2px solid @gray-lighter;
	background-color : @white;
	color : @gray-lighter;
	display : inline-block;
	line-height : 18px !important;
}
a.kobutton {
	border : 2px solid @black;
	background-color : @white;
	
	.transition-header-zeropointone;
	
	&:hover {
		border : 2px solid @comred;
		color : @comred;
		text-decoration : none;
	}
}