.pahomebox {
    .zeropm;
    
    a {
        .zeropm;
        
        &:hover {
            text-decoration : none;
        }
        
        .innerblock {
            .zeropm;
            min-height : 150px;
            max-height : 200px;
            background-color :  @black-trans;
            border : 1px solid @black-moretrans;
            
        	-webkit-transition : all 0.2s ease-in-out 0.2s;
        	-moz-transition : all 0.1s ease-in-out 0s;  
        	-ms-transition : all 0.1s ease-in-out 0s; 
        	-o-transition : all 0.1s ease-in-out 0s; 
        	transition : all 0.1s ease-in-out 0s;       
	
            &:hover {
                background-color :  @black-moretrans;
                border : 1px solid @black-moretrans;
            }
        }
    }
}