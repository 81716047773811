
// -------------------------------------------
// HOME - Social Media

// Frame
.socialfeeds {
	margin-top : 20px;
	
	.sfintrobox {
		padding : 20px 20px;
		background-color : @brand-primary-two-darkish;
		color : @white;
		
		h3 {
			.zeropm;
		}
	}
}

// Feeds
.homespots {
	min-height: 650px;
	border: 0px none;
	//margin-bottom: -1000px;
	//padding-bottom: 1000px;
	
	.innerblock {
		padding: 0px 0px 20px 0px;
	}
	
	&.feedbox {
		
		h3 {
			color : @white;
			font-size : @font-size-h4;
			margin-top : 20px;
			margin-bottom : 40px;
			
			small {
				color : @white;
			}
		}
		.feedlayout {
			background-color : @white;
		}
		&.tweets {
			background-color : @brand-primary-two-light;
			
			h3 {
				color : @brand-primary-two-dark;
				
				small {
					color : @brand-primary-two-dark;
				}				
			}
		}
		&.fbposts {
			background-color : @brand-primary-three-light;
		}
	}
}



// -------------------------------------------
// Not needed?

.fbfeedbox {
	color : #222222;
	
	h3 {
		color : #FFFFFF;
	}
	a {
		color : #4C8187;
		
		&:hover {
			color : #75B9BE;
		}
	}
	#cff {height : 480px !important;}
}