
// ---------------------------------------------------------------------
// FSA Set Styles

@font-family-fsa-main:  Open Sans, sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;

@verylightblue : rgba(168, 213, 255, 0.5);

.innershadow {
	-webkit-box-shadow: inset 0px 0px 103px -15px rgba(33,33,33,0.81);
	-moz-box-shadow: inset 0px 0px 103px -15px rgba(33,33,33,0.81);
	box-shadow: inset 0px 0px 103px -15px rgba(33,33,33,0.81);
}

.shadowbox {
    margin-top : -20px;
    height : 7px;
    width : 100%;
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.01) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.01) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.01) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#03000000',GradientType=0 ); /* IE6-9 */
}


// ---------------------------------------------------------------------
// Page Section Master Styles
.fsamain {
	background-image : url(../img/bg_grass_002.jpg); 
	margin-top : 40px;


	// ---------------------------------------------------------------------
	// Main FSA logo
	.tophead {
	    height : 0px !important;
	    margin : 0px !important;
	    padding : 0px !important;
	    position : relative;
	    z-index : 1000;
		display : inline;
	    
	    .thbg {
	        position : relative;
	        padding-top : 10px;
	        padding-bottom : 10px;
	        .gradient-whitetransINV;
	        
	        h1 {
	            margin : 0px;
	            width : 100%;
	            height : 233px;
	            background : url("../img/LOGO_FA-SportsAssociation_500x233.png") no-repeat center center;
	    
	            span {
	                display : none;   
	            }
	        }        
	    }
	}
	@media only screen and (min-width: @screen-lg) {
	.tophead {
	    .thbg { 
	    }
	}
	}
	@media only screen and (min-width: @screen-sm) and (max-width: @screen-md) {
	.tophead {
	    .thbg {
	        h1 {
	            height : 186px;
	            background : url("../img/LOGO_FA-SportsAssociation_400x186.png") no-repeat center center;
	        }        
	    }
	}
	}
	@media only screen and (min-width: @screen-xs) and (max-width: @screen-sm) {
	.tophead {
	    .thbg {
	        h1 {
	            height : 139px;
	            background : url("../img/LOGO_FA-SportsAssociation_300x140.png") no-repeat center center;
	        }
	    }
	}
	}
	@media only screen and (min-width: 0px) and (max-width: @screen-xs) {
	.tophead {
	    .thbg {
	        h1 {
	            height : 93px;
	            background : url("../img/LOGO_FA-SportsAssociation_200x93.png") no-repeat center center;
	        }
	    }
	}
	}


	// ---------------------------------------------------------------------
	// Introduction 

    .introsection {
        .zeropm;
        padding-top : 10px;
        font-family : @font-family-fsa-main !important;
        font-weight : 700;
		background-color : @white-trans !important;           


		// Objectives Text        
        .maincontent {
        	margin-top : 20px;
        }
	
	
		// Objectives Text
		.introwords {
			padding : 40px;
			margin : 10px 10px 60px 0px;
			background-image : url(../img/bg_paper_002.jpg);
			background-size : contain;
			background-position : center;
			
			font-family: 'Special Elite', cursive;
			font-weight : normal;
			color : @black;
			
			position : relative;
			top : 20px;
			
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
			
			-webkit-box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
			-moz-box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
			box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
			
			-ms-transform: rotate(1.5deg); 
			-webkit-transform: rotate(1.5deg); 
			transform: rotate(1.5deg);			
		}

    }



	// ---------------------------------------------------------------------
	// News Boxes
	.websitenewssection {
		.zeropm;
	    
	    .introsection {
	        .zeropm;
	        padding-top : 20px;
	        background-image : url(../img/bg_paper_002.jpg);
	        background-position : center;
	    }
	    
	    // Heading
	    .newsfalliposts {
	        .gradient-BGimageshade;
	        font-family: 'Architects Daughter', cursive;
	    
	        .innerblock {
	            padding : 20px;
	            padding-bottom : 60px;
	            
	            ul {
	                padding-left : 0px !important;
	            }
	        }
	        
	        .heading {
	            margin-bottom : 20px;
	            margin-left : auto;
	            margin-right : auto;
	            background-image : url(../img/bg_paper_002.jpg);
	            background-position : center;
	            text-align : center;
	            width : 50%;
	            
	            -ms-transform: rotate(-1deg); 
	            -webkit-transform: rotate(-1deg); 
	            transform: rotate(-1deg);
	        
	            -webkit-box-shadow: 0px 1px 3px rgba(0,0,0,0.5);
	            -moz-box-shadow: 0px 1px 3px rgba(0,0,0,0.5);
	            box-shadow: 0px 1px 3px rgba(0,0,0,0.5);
	            
	            h2 {
	                padding : 20px;
	                margin : 0px auto 10px auto;
	                font-size : floor((@font-size-h1 * 1.2));
	                color : @gray-darker;
	                
	                text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
	                
	                background-color : rgba(255,0,255,0.2);
	            }            
	        }
	    }
	    
	    // News Boxes
	    .websitenewslink {
	        background-image : url(../img/bg_paper_002.jpg);
	        background-position : center;
	        
	        -ms-transform: rotate(0.75deg); 
	        -webkit-transform: rotate(0.75deg); 
	        transform: rotate(0.75deg);
	        
	        -webkit-border-radius: 2px;
	        -moz-border-radius: 2px;
	        border-radius: 2px; 
	        
	        -webkit-box-shadow: 0px 1px 3px rgba(0,0,0,0.5);
	        -moz-box-shadow: 0px 1px 3px rgba(0,0,0,0.5);
	        box-shadow: 0px 1px 3px rgba(0,0,0,0.5);
	        
	        .zeropm;
	        
	        a {
	            color : @black;
	            
	            &:hover {
	                text-decoration : none;
	                color : @black-trans;
	            }
	        }
	
	        .stlenv {
	            padding : 10px 20px 30px 20px;
	            text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
	            
	            .span2 {
	                //height : 150px;
	                width : 100%;
	                margin-bottom : 20px;
	                
	                filter: contrast(2.5) grayscale(100%) blur(0.5px);
	                -webkit-filter: contrast(2.5) grayscale(100%) blur(0.5px);
	                -moz-filter: contrast(2.5) grayscale(100%) blur(0.5px);
	                -o-filter: contrast(2.5) grayscale(100%) blur(0.5px);
	                -ms-filter: contrast(2.5) grayscale(100%) blur(0.5px);
	                
	                opacity : 0.65;
	                
	                -webkit-border-radius: 2px;
	                -moz-border-radius: 2px;
	                border-radius: 2px;
	            }
	            .righttext {
	                float : right;
	            }
	        }
	        
	        &:nth-child(8n+1) {
	            -ms-transform: rotate(-1deg); 
	            -webkit-transform: rotate(-1deg); 
	            transform: rotate(-1deg);
	            
	            .stlenv {
	                background-color : rgba(255,255,0,0.2);
	            }
	        }
	        &:nth-child(8n+3) {
	            -ms-transform: rotate(1.75deg); 
	            -webkit-transform: rotate(1.75deg); 
	            transform: rotate(1.75deg);
	            
	            .stlenv {
	                background-color : rgba(255,0,0,0.2);
	            }
	        }
	        &:nth-child(8n+5) {
	            -ms-transform: rotate(-0.55deg); 
	            -webkit-transform: rotate(-0.55deg); 
	            transform: rotate(-0.55deg);
	            
	            .stlenv {
	                background-color : rgba(0,255,0,0.2);
	            }
	        }
	        &:nth-child(8n+7) {
	            -ms-transform: rotate(0.85deg); 
	            -webkit-transform: rotate(0.85deg); 
	            transform: rotate(0.85deg);
	            
	            .stlenv {
	                background-color : rgba(0,0,255,0.2);
	            }
	        }        
	    }
	    
	    // Modal window
	    .websitenewspop {
	            
	        &:nth-child(8n+2) {
	            .modal-dialog {
	                -ms-transform: rotate(-1.5deg); 
	                -webkit-transform: rotate(-1deg); 
	                transform: rotate(-1deg);
	            }
	            .modal-content {
	                background-color : rgba(255,255,0,0.2);
	            }
	        }
	        &:nth-child(8n+4) {
	            .modal-dialog {
	                -ms-transform: rotate(1.5deg); 
	                -webkit-transform: rotate(1.5deg); 
	                transform: rotate(1.5deg);
	            }
	            .modal-content {
	                background-color : rgba(255,0,0,0.2);
	            }
	        }
	        &:nth-child(8n+6) {
	            .modal-dialog {
	                -ms-transform: rotate(-0.55deg); 
	                -webkit-transform: rotate(-0.55deg); 
	                transform: rotate(-0.55deg);
	            }
	            .modal-content {
	                background-color : rgba(0,255,0,0.2);
	            }
	        }
	        &:nth-child(8n+8) {
	            .modal-dialog {
	                -ms-transform: rotate(0.85deg); 
	                -webkit-transform: rotate(0.85deg); 
	                transform: rotate(0.85deg);
	            }
	            .modal-content {
	                background-color : rgba(0,0,255,0.2);
	            }
	        }
	    
	        .modal-dialog {
	            margin-top : 40px;
	            position : relative;
	            
	            -webkit-border-radius: 1px;
	            -moz-border-radius: 1px;
	            border-radius: 1px;
	            
	            background-image : url(../img/bg_paper_002.jpg);
	            background-position : center;
	            
	            .profilethumb {
	                height : 300px;
	                width : 100%;
	            
	                -ms-transform: rotate(0deg) !important; 
	                -webkit-transform: rotate(0deg) !important; 
	                transform: rotate(0deg) !important;
	            }
	            
	            .modal-content {
	            
	                .modal-header {
	                    border-bottom : 0px none;
	                    
	                    h4 {
	                        &.modal-title {
	                            font-size : 40px !important;
	                        }
	                    }
	                }
	                .modal-footer {
	                    border-top : 0px none;
	                    text-align : right;
	                }
	            }
	            
	            .modal-body {
	                padding-top : 0px;
	                
	                h5 {
	                    font-size : 30px !important;
	                }
	            }
	        }
	    
	    }    
	    
	}
	
	@media only screen and (min-width: 0px) and (max-width: @screen-sm) {
	.websitenewssection {
	    .newsfalliposts {
	        .heading {
	            width : 80%;
	        }
	    }
	}
	}


	// ---------------------------------------------------------------------
	// Membership Box
	.signuppop {
	    margin-top : 30px;
	    
	    .modal-content {
	        -ms-transform: rotate(-1deg); 
	        -webkit-transform: rotate(-1deg); 
	        transform: rotate(-1deg);        
	    }
	    
	    .modal-header {
	        padding-top : 5px !important;
	        padding-bottom : 27px !important;
	    }
	    .modal-body {
	        padding : 0px !important;
	    }
	    .modal-footer {
	        margin-top : 0px !important;
	    }
	}

}


// ---------------------------------------------------------------------
// Instagram Box
.instapic {
	width : 33.3% !important;
	max-height : 300px !important;
	overflow-y : hidden;
	float : left !important;
		
	img {
		width : 100% !important;
	}
}