// Template-specific elements
.yeargroup-template-default {
	
	.contentarea {
		padding : 20px 20px !important;
	}
	
	// Set RHS padding to fill space
	.yprhs {
		//.zeropm;
	}
}



// -------------------------------------------
// SECTION BUTTONS
.ypsectionbuttons {
	margin-bottom : 30px;
	box-shadow : 0px 0px 7px rgba(0,0,0,0.3);
	
	li {
		margin-left : 0px !important;
		font-weight : 700;
		font-size : 15px;
		text-align : center;
		
		background-color : @brand-primary-two-light;
		color : @brand-primary-two;

		// Heading
		&:first-child {
			padding : 30px 20px;
			background-color : @brand-primary-one;
			color : @white;
			
			span {
			}
		}

		// buttons		
		a {
			padding : 30px 20px;
			margin-bottom : 0px;
			border-radius : 0px;
			
			background-color : @brand-primary-three-light;
			color : @white;
			
			.transition-header-zeropointtwofive;
			
			&:hover {
				text-decoration : none;
				background-color : @brand-primary-three-lightest-more;
			}
		}

		// alternate button			
		&:nth-child(2n+3) {

			a {
				background-color : @brand-primary-two;
				color : @white;
				
				&:hover {
					background-color : @brand-primary-two-light;
				}
			}
		}
	}
}



// -------------------------------------------
// TAB MENU CONTENT BUTTONS
.yptabcontent {
	margin-bottom : 0px;
		
	.btnbacktocurric {
		background-color : @brand-primary-two !important;
	}
	
	li {
		background-color : @brand-primary-two-light;
		color : @brand-primary-two;
		
		// buttons		
		a {
			padding : 20px 10px;
			margin-bottom : 0px;
			border-radius : 0px;
		}
	}
}



// -------------------------------------------
// LEARNING MANAGER
.ypmeetlm {
	position : relative;
	box-shadow : 0px 0px 7px rgba(0,0,0,0.3);
	text-align : center;
	
	.panel-heading {
		background-color : @brand-primary-one-moretrans;
		border : 0px none;
		color : @white;
		
		small {
			color : @white;
		}
	}
	
	.panel-body {
		.zeropm;
		position : relative;
	}
	
	.panel-footer {
		background-color : @brand-primary-one-shade-trans;
		border : 0px none;
		color : @white;
		
		p {
			
			&.yplmname {
				font-size : 20px;
			}
		}
		.btn {
			font-size : 12px;
		}
	}
}


// -------------------------------------------
// FORM TUTORS - Takes base styles from LEARNING MANAGER styles
.stafflistlayout {
	display : flex;
	flex-wrap: wrap;
	
	.ypformtutor {
		display : flex;
		flex-direction: column;
	}
}
.ypformtutors {
	height : 100%;
	background-color : @brand-primary-one;
	
	.panel-footer {
		vertical-align : bottom;
		
		p {
			font-size : @font-size-small;
			line-height : @font-size-base;
		}
		
		.ypftemail {
			font-size : 12px;
		}
	}
}


// -------------------------------------------
// LEARNING MANAGER - within content area
.uiclmcontact {
	
	p {
		margin-bottom : 0px;
	}
}
