
// Page Header
.page-header-surround {
	position : absolute;
	bottom : 0px;
	width : 100%;

	.page-header {
	    text-align : center;
	    margin : 0px auto 0px auto;
	    padding : 0px 0px 0px 0px;
	    border-bottom : 0px none;
	    max-width : 1500px;
	    
		.mpswelcomecontent {
			background-color : @brand-primary-one-trans !important;
			text-align : center;
		}
	    
	    .page-header-banner {
	        //height : 300px;
	        border-top : 1px solid @gray;
	        border-bottom : 1px solid @gray;
	        background-size : cover;
	        background-position : center center;
	        
	        &.shallow {
	        	height : 100px;
	        }
	        &.tall {
	        	height : 700px;
	        }
	        &.hide {
	        	height : 0px;
	        }
	    }
	
	    h2 {
	        .zeropm;
	        padding-top : 30px;
	        padding-bottom : 30px;
	        color : @white !important;
	        text-transform : uppercase;
	        font-weight : 700;
	        font-size : 50px !important;
	        text-align : center;
	        letter-spacing: 2px;
	        
	        &.inverse {
	        	//color : @white !important;
	        	color : @brand-primary-four !important;
	        }
	    }
	    
	    p {
	    	margin-bottom : 0px;
	    	font-size : 0.6em;
	    }
	    
	    .homebuttondept {
	        
	    }
	}
}
@media only screen and (min-width: 0px) and (max-width: @screen-xs) {
.page-header {
    .page-header-banner {
        height : 150px;
    }
    h2 {
        font-size : 30px !important;
    }
}
}
@media only screen and (min-width: @screen-xs) and (max-width: @screen-sm) {
.page-header {
    .page-header-banner {
        height : 200px;
    }   
    h2 {
        font-size : 35px !important;
    } 
}
}


// Page Details bar
.pagedetailsbar {
	background-color : @brand-primary-one-trans;
    color : @white;
    font-size : 14px !important;
}

// Page Category Title
.preheadercontent {
	padding : 5px 0px 5px 40px;
	text-transform : uppercase;
    font-weight : 500;
    text-align : left;
    letter-spacing: 2px;
}

// Page Titles
.headercontent {
    text-align : right;
    
    p {
		padding : 2px 30px 2px 0px;
    	margin-bottom : 0px;
    }
	
	a {
		color : @white;
		font-weight : 700;
		
    	&.btn {
    		&.btn-default {
    			color : #333333 !important;
    		}
    	}
	}
}


// Breadcrumb (content pages)
.breadcrumb {
    margin-bottom : 0px;
    text-align : left;
    //background-color : @gray-light;
    background-color : @brand-primary-one;
    color : @white;
    border-radius : 0px;
    
    a {
        color : @white !important;
        &:hover {
              color : @white-trans !important;
              text-decoration : none !important;
        }
    }
}


// Post Type Specific Styles

// News posts
.phcnews {
	
	.preheadercontent {
		background-color : @brand-primary-three;
	}
    h2 {
        color : @brand-primary-three !important;
    }
}

// School Info posts
.phcschoolinfo {
	
	.preheadercontent {
		background-color : @brand-primary-two;
	}
    h2 {
        color : @brand-primary-two !important;
    }
}