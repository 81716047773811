.uniformlayout {
        
    .mainuniheading {
        margin-top : 0px !important;
        margin-bottom : 20px !important;
    }

    .uniformimage {
        width : 100%;
        
        .frame {
            padding-top : 20px;
            margin : 0px auto 0px auto;
            width : 300px;
            height : 500px;
            background-repeat : no-repeat;
            background-position : center;
            background-size : cover;
            position : relative;
        }
        
        .btn {
            font-size : 12px;
            padding : 5px;
            line-height : 12px;
            
            position : absolute;
            left : 10px;
        }
        
        .inst {
            font-size : 12px;
            line-height : 13px;
            text-align : center;
            position : absolute;
            top : -10px;
            width : 100%;
        }
        
        &.girls {
            
            .frame {
                background-image : url('../img/Uniform-Female-SITE.png');
            
                .makeup {
                    top : 40px;
                }
                .tie {
                    top : 80px;
                }
                .blouse {
                    top : 110px;
                }
                .blazer {
                    top : 140px;
                }
                .jumper {
                    top : 170px;
                }
                .skirt {
                    top : 280px;
                }
                .tightssocks {
                    top : 350px;
                }
                .shoes {
                    top : 460px;
                }
            }
        }

        &.boys {
            
            .frame {
                background-image : url('../img/Uniform-Male-SITE.png');
            
                .makeup {
                    top : 40px;
                }
                .shirt {
                    top : 110px;
                }
                .tie {
                    top : 80px;
                }
                .blazer {
                    top : 140px;
                }
                .jumper {
                    top : 170px;
                }
                .trousers {
                    top : 290px;
                }
                .socks {
                    top : 420px;
                }
                .shoes {
                    top : 460px;
                }
            }
        }
    }

    #expcontent {
        float: left;
        width: 100%;
        margin-top : 20px;
        margin-bottom : 20px;
        padding-left : 0px;
        
        li {
            list-style-type : none;
            
            a {
                display: block;
                padding: 10px 15px;
                background: #ccc;
                border-top: 1px solid #eee;
                border-bottom: 1px solid #999;
                text-decoration: none;
                color: #000;
                &:hover (
                    background: #999;
                    color: #fff;               
                )
                &.active {
                    background: #999;
                    color: #fff;
                }
                .caret {
                    float : right;
                    margin-top : 10px;
                }
            }
            .cont {
                display: none; // used to hide sub-menus
                
                a {
                    display: inline;
                    padding: auto auto;
                    background: transparent;
                    border-top: 0px none;
                    border-bottom: 0px none;
                    text-decoration: none;
                    color: #000;
                }
                
                table {
                    
                    th {
                        padding : 10px;
                        text-align : center;
                        background-color : #eeeeee;
                    }
                    td {
                        padding : 10px;
                        text-align : center;
                    }
                }
            }
        }
        
        .cont {
            padding : 10px;
            background-color : #eeeeee;
            
            li {
                list-style-type : circle;
            }
        }       
    }
}