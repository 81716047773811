// Temped colours

@brand-primary-ebb : #AD8300;
@brand-primary-bg-ebb : #FFE9A6;
@brand-primary-bg-light-ebb : fade(@brand-primary-bg-ebb, 40%);

@brand-success-ebb : #3c763d;
@brand-success-bg-ebb : #dff0d8;
@brand-success-bg-light-ebb : fade(@brand-success-bg-ebb, 40%);

@brand-info-ebb : #31708f;
@brand-info-bg-ebb : #d9edf7;
@brand-info-bg-light-ebb : fade(@brand-info-bg-ebb, 40%);

@brand-warning-ebb : #8a6d3b;
@brand-warning-bg-ebb : #fcf8e3;
@brand-warning-bg-light-ebb : fade(@brand-warning-bg-ebb, 40%);

@brand-danger-ebb : #a94442;
@brand-danger-bg-ebb : #f2dede;
@brand-danger-bg-light-ebb : fade(@brand-danger-bg-ebb, 40%);


// Styles
.virtual-open-evening {
	
	// Remove spacing under header image
	.breadcrumb { display : none; visibility : hidden; }
	
	// Well BG colour
	.bgtransoverlay .well {
		background-color : #FFFFFF !important;
		border : 0px none;
		
		-webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
	}
	
	// Main header styling
	.page-header {
		padding-bottom : 0px !important;
	}
	.page-header h2 {
 		margin-top : 0px;
 		padding : 20px 0px 30px 0px;
 		background-color : #B3B68D;
 		color : #FFFFFF;
 		font-size : 50px;
	}
	.page-header h2 small {
 		color : #FFFFFF;
	}
	
	// Modal styles
	.modal-header {
		background-position : center center;
		background-size : cover;
		height : 250px;
		position : relative;
		padding : 15px 0px;
	}
	.modal-title {
		color : #FFFFFF;
		position : absolute;
		bottom : 10px;
		line-height : 1em; 
		width : 100%;
		
		small {
			color : #FEFEFE;
		}
	}
	
	// Page nav
	.vodtmenu {
		
		.vodtmenulink {
			text-align : center;
			
			a {
				padding : 40px 10px;
				width : 100%;
				height : 100%;
				display : block;
				font-weight : 700;
				
				.basictransition;
				
				&:hover {
					text-decoration : none;
				}
			}
			
			&.vodt001 {

				a {
					background-color : @brand-success-bg-ebb;
					color : @brand-success-ebb;
				
					&:hover {
						background-color : @brand-success-bg-light-ebb;
					}
				}
			}
			&.vodt002 {

				a {
					background-color : @brand-info-bg-ebb;
					color : @brand-info-ebb;
				
					&:hover {
						background-color : @brand-info-bg-light-ebb;
					}
				}
			}
			&.vodt003 {

				a {
					background-color : @brand-warning-bg-ebb;
					color : @brand-warning-ebb;
				
					&:hover {
						background-color : @brand-warning-bg-light-ebb;
					}
				}
			}
			&.vodt004 {

				a {
					background-color : @brand-danger-bg-ebb;
					color : @brand-danger-ebb;
				
					&:hover {
						background-color : @brand-danger-bg-light-ebb;
					}
				}
			}
			&.vodt005 {

				a {
					background-color : @brand-primary-bg-ebb;
					color : @brand-primary-ebb;
				
					&:hover {
						background-color : @brand-primary-bg-light-ebb;
					}
				}
			}
			&.vodt006 {

				a {
					background-color : @brand-primary-bg-ebb;
					color : @brand-primary-ebb;
				
					&:hover {
						background-color : @brand-primary-bg-light-ebb;
					}
				}
			}
		}
	}
	
	// Panels
	.panel {
		
		
		.panel-heading {
			text-align : center;
			border-bottom : 1px solid black;
			
			.panel-title {
				padding : 20px 0px 10px 0px;
				font-size : @font-size-h2;
				font-weight : normal !important;
			}
		}
		
		&.panel-success {

			.panel-heading {
				border-bottom : 1px solid @brand-success-ebb;
			}
			.panel-body {
				background-color : @brand-success-bg-light-ebb;
			}
			.panel-footer {
				background-color : @brand-success-bg-ebb;
				border-top : 1px solid @brand-success-ebb;
			}
		}
		
		&.panel-info {

			.panel-heading {
				border-bottom : 1px solid @brand-info-ebb;
			}
			.panel-body {
				background-color : @brand-info-bg-light-ebb;
			}
			.panel-footer {
				background-color : @brand-info-bg-ebb;
				border-top : 1px solid @brand-info-ebb;
			}
		}
		
		&.panel-warning {

			.panel-heading {
				border-bottom : 1px solid @brand-warning-ebb;
			}
			.panel-body {
				background-color : @brand-warning-bg-light-ebb;
			}
			.panel-footer {
				background-color : @brand-warning-bg-ebb;
				border-top : 1px solid @brand-warning-ebb;
			}
		}
		
		&.panel-danger {

			.panel-heading {
				border-bottom : 1px solid @brand-danger-ebb;
			}
			.panel-body {
				background-color : @brand-danger-bg-light-ebb;
			}
			.panel-footer {
				background-color : @brand-danger-bg-ebb;
				border-top : 1px solid @brand-danger-ebb;
			}
		}
		
		&.panel-primary {

			.panel-heading {
				background-color : @brand-primary-bg-ebb;
				border-bottom : 1px solid @brand-primary-ebb;
				color : @brand-primary-ebb;
			}
			.panel-body {
				background-color : @brand-primary-bg-light-ebb;
			}
			.panel-footer {
				background-color : @brand-primary-bg-ebb;
				border-top : 1px solid @brand-primary-ebb;
				
				.btn-primary {
					background-color : @brand-primary-ebb !important;
				}
			}
		}
	}
	
} // END .virtual-open-day
