// RE:PLAY layouts
.replaytemp {
    
    background: #000000;
    color: #ffffff;
    border-bottom: 0px none;
    padding-bottom: 30px;
    .gradient-blacksubtle;    

	// Replay top logo
	.page-header {
	    background: #000000;
	    color: #ffffff;
	    border-bottom: 0px none;
	    padding-bottom: 30px;
	    margin-bottom : 0px;
	    .gradient-blacksubtle;
	    
	    h1 {
	        padding-top : 30px;
	        padding-bottom : 20px;
	        color : #ffffff !important;
	        width : 100%;
	        
	        &.rphometitle {                
	            position : relative;
	            top : 20px;
	            background-image : url(../img/replay_logo.fw.png);
	            background-repeat : no-repeat;
	            background-position : center;
	            height : 125px;
	        }
	    }
	}
	
	// Content blocks
	.tab-content {
		padding : 20px 20px;
		
		.rpmainheading {
			.zeropm;
			text-align : center;
	        padding-bottom : 30px;
	        color : #ffffff !important;
	        width : 100%;
	        font-size : 50px;
	        text-transform : uppercase;
		}
	}

    // Content Styles
    .redtext {
        color : @brand-primary-rpred;
    }
    .btn-primary {
         background-color : @brand-primary-rpred;
         border : 1px solid @brand-primary-rpred;
         
        -webkit-transition: all 0.1s ease-in-out 0s;  
        -moz-transition: all 0.1s ease-in-out 0s;  
        -ms-transition: all 0.1s ease-in-out 0s; 
        -o-transition: all 0.1s ease-in-out 0s; 
        transition: all 0.1s ease-in-out 0s;
         &:hover {
             background-color : @brand-primary-rpred-dark;
         }
    }
    input, textarea {
        color : @brand-primary-rpred-dark !important;
    }
    .replaynav {
        padding-bottom : 0px;
        border-bottom : 0px none;

        li {
            
            a {
            	background-color : @black;
                
                -webkit-transition: all 0.1s ease-in-out 0s;  
                -moz-transition: all 0.1s ease-in-out 0s;  
                -ms-transition: all 0.1s ease-in-out 0s; 
                -o-transition: all 0.1s ease-in-out 0s; 
                transition: all 0.1s ease-in-out 0s; 
                
                &:hover {
            		background-color : @brand-primary-rpred-dark !important;
            		color : @white !important;
                }
            }
                
            &.active {
            	
            	a {
	            	background-color : @brand-primary-rpred-dark !important;
	            	color : @white !important;
            	}
            }
        }
    }

        
   
        a {
             color : @brand-primary-rpred !important;
             text-decoration : none;
            
            -webkit-transition: all 0.1s ease-in-out 0s;  
            -moz-transition: all 0.1s ease-in-out 0s;  
            -ms-transition: all 0.1s ease-in-out 0s; 
            -o-transition: all 0.1s ease-in-out 0s; 
            transition: all 0.1s ease-in-out 0s; 
             
             &:hover {
                color : @brand-primary-rpred-dark !important;
             }
        }
        .replaycont {
            background: #000000;
            color: #ffffff;            
            .introcontentarea {
                background: #000000;
                color: #ffffff;
            }
        }

}