// HEADER Layout
header {
	background-color : @white-moretrans !important;
	//height : 100vh !important;
	width : 100% !important;
	position : absolute !important;
	top : 0px !important;
	z-index : 1000;
	
	.container {
		margin : 0px auto 0px auto !important;
		max-width : 1500px !important;

		.innercontainer {
			padding : 0px 10px;
			.transition-header-zeropointone;
		}
		
		&.nopad {
			margin : 0px 0px 0px 0px !important;
			max-width : 100% !important;
			background-color : @brand-primary-one-moretrans;
			
			// COVID BUTTONS - Temp section
			&.secondarylevel {
				border-bottom : 1px solid @brand-primary-one-light;
				background-color : @white-trans !important;
				
				
				.headerbarcovid {
					text-align : center;
					
					ul {
						.zeropm;
						margin-bottom : 7px;
						
						li {
							display : inline;
							
							a {
								margin-top : 7px;
								color : #ffffff; 
								font-size : 11px;
							}
						}
					}
				}
			}
			
			.innercontainer {
				margin : 0px auto 0px auto !important;
				padding : 0px 0px !important;
				max-width : 1500px !important;
			}
		}
		
		
		@media only screen and (min-width: 1510px) {
		.innercontainer {
			padding : 0px 0px;
		}
		}
	}
	
	&.banner {
	    //height : 103px !important;
	    background-color : @white-trans;
    	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
	}	    
    @media only screen and (min-width: 0px) and (max-width: @screen-sm) {
	&.banner {
		//height : 110px !important;
	}
	}
}
// Move content down to accomodate Wordpress bar
.logged-in header {
	top : 32px !important;
}

.navbar-static-top {
	border-bottom : 0px none;
}


// -------------------------------------------
// HEADER - Homepage
.home {
	
	header {
		//background-color : @white-trans !important;
		//margin-top : 32px;
		//position : absolute !important;
		//top : 0px !important;
		//height : 100vh !important;
		//width : 100% !important;
		//background-color : @brand-primary-one-trans !important;
	}
}


// -------------------------------------------
// Main logo
.tophead {
    margin : 0px 0px 0px 0px;
    padding : 10px 0px;
    
    a.navbar-brand {
        margin : 0px 0px 0px 0px !important;
        padding : 0px 0px 0px 0px !important;
        height : 75px;
        width : 202px;
        display : block;
        z-index : 10;
        background : url("../img/logo_head_link.png") no-repeat top left;
        
        &:hover {
            background : url("../img/logo_head_link.png") no-repeat bottom left;
        }
        
        h1 {
            span {
                display : none;   
            }
        }
    }
    &.fasftoplogo {
        a.navbar-brand {
            background : url("../img/logo_head_FA6th_link.fw.png") no-repeat top left;
            &:hover {
                background : url("../img/logo_head_FA6th_link.fw.png") no-repeat bottom left;
            }
        }
    }
}
// Make logo center-aligned on XS screens
@media only screen and (min-width: 0px) and (max-width: @screen-sm) {
.tophead {
	a.navbar-brand {
		width : 100%;
		background-position : top center;
		&:hover {
			background-position : bottom center;
		}
	}
}
}


// -------------------------------------------
// Top right links 
.toplinks {
	.zeropm;
	
	.maintoplinks {
		float : right;
	}
	.searchtoplinks {
		float : right;
	}
}
@media only screen and (min-width: 0px) and (max-width: @screen-sm) {
.toplinks {
    .maintoplinks {
    	float : none;
    }
}
}
	
.toprighttext {
	.zeropm;
	text-align : right;
    font-weight : 700 !important;
    font-size : 12px !important;
    white-space: nowrap;
    
    &.trustlinks {
        .zeropm;
        margin-top : 10px;
        color: #bcba97;
        
        a { 
            color: @brand-primary-one; 
            text-decoration: none;
        
            .transition-header;
        
            &:hover {
                color: @brand-primary-one-trans;
            }
        }
        
        // Department links - PA and sports etc.
        .toprightnew {
        	margin-top : 5px;
        	
	        a { 
	            color: @brand-primary-three; 
	            text-decoration: none; 
	            font-weight: normal;
	            
	            .transition-header;
	            
	            &:hover {
	                color: @brand-primary-three-trans;
	            }
	        } 
        }
    }
}


// -------------------------------------------
// Absence Line buttons
.absencelinefull {
	text-align : center;
}
.absenceline {
    .zeropm;
    margin-top : 10px;
    
    .schoolabsenceline {
    	white-space: nowrap;
    	
    	.label {
    		font-size : 11px;
    		white-space: nowrap;
    	}
    }
}
// Make Absence Line buttons center-aligned on XS screens
@media only screen and (min-width: 0px) and (max-width: @screen-sm) {
.absenceline {
	text-align : center;
}
}



// ---------------------------------------------
// REFERENCE
// For main navigation styles - see MainNavigation.less
// For search related styles - see Search.less