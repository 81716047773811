// FOOTER
.home {
    footer {
        margin-top : 0px !important;
    }
}
footer {
    background : @brand-primary-one;
    margin-top : 0px;
    color : @white;
    margin-top : 30px;
    
    .container {
		margin : 0px auto 0px auto !important;
		max-width : 1500px !important;
    }
    
    .footerlinks {
        padding-left : 0px !important;
        padding-right : 0px !important;
        height : 47px;
        vertical-align : baseline;
        display : none;
        visibility : hidden;
    }
	.footerlinksmenu { 
		background : @white;
		text-align : center; 
		height : 47px;
		line-height : 16px;

		li { 
			height:47px; 
			position:relative; 
			display: inline-block; 
			*display:inline; 
			*zoom:1; 
			max-width: 217px;  
			text-align: center; 
			width: 19%;

			a { 
				color: #4c8187; 
				font-size: 14px; 
				background: repeat-x center bottom transparent; 
				border-top: 2px solid transparent; 
				display: block; 
				padding: 15px 0px 19px; 
				text-decoration: none; 
				text-transform: uppercase; 
				position:absolute; 
				bottom: 0; 
				width:102%; 
				
				-webkit-transition : all 0.1s ease-in-out 0s;  
				-moz-transition : all 0.1s ease-in-out 0s;  
				-ms-transition : all 0.1s ease-in-out 0s; 
				-o-transition : all 0.1s ease-in-out 0s; 
				transition : all 0.1s ease-in-out 0s; 
				
                height : 52px;

				&:hover { 
					background: url("../img/secondaryLinkBg.png") repeat-x scroll center bottom ;
					border-top: 2px solid transparent;
					color: #fff;
					height: 40px;
					height : 66px;
					text-decoration: none;
				} 
			}
		}
	}
    
    .footboxes {
        padding : 10px 10px;
        margin : 0px;
        font-size : 12px;
    
        &.fbleft {
            text-align : left;   
        }
    
        &.fbright {
            text-align : right;   
        }
    }
    
    .footlink {
        .zeropm;
        
        li {
            .zeropm;
            list-style-type : none;
            //float : left;
            display : inline-block;
            margin-right : 20px;
            
            &:hover {
                opacity: 0.4;
                filter: alpha(opacity=40); /* For IE8 and earlier */   
            }
            
            a {
                color : @brand-primary-two;
            }
            
            .linklogo {
                height : 30px;
                width : 30px;
                display : block;
                
                span {
                    display : none;
                    visibility : hidden;
                }
                
                &.linkfb {
                    background : url("../img/icon_facebook_sm.png") no-repeat top left;
                }
                &.linktw {
                    background : url("../img/icon_twitter_sm.png") no-repeat top left;
                }
                &.linkyt {
                    background : url("../img/icon_youtube_sm.png") no-repeat top left;
                }
                &.linkvim {
                    background : url("../img/icon_vimeo_sm.png") no-repeat top left;
                }
                &.linkli {
                    background : url("../img/icon_linkedin_sm.png") no-repeat top left;
                }
                &.linkemail {
                    background : url("../img/icon_email_sm.png") no-repeat top left;
                }
                &.linkpto {
                    background : url("../img/icon_pto.png") no-repeat top left;
                }
                &.linkwisepay {
                    background : url("../img/icon_wisepay_sm.png") no-repeat top left;
                    width : 84px!important;
                }
                &.linkgoogleclassroom {
                    background : url("../img/icon_gclass_sm.png") no-repeat top left;
                }
            }
        }  
    }

    a.linkbutton_silkalliance {
        background : url("../img/logo_sa_footer.png") no-repeat top left;
        width : 204px;
        height : 82px;
        display : block;
        margin : 0px auto;
        
        span {
            display : none;
            visibility : hidden;
        }
    }
    
    .frow {
        padding-top : 13px;
    }
    a {
       

        &:hover {
            
        }
    }
}
@media only screen and (min-width: 0px) and (max-width: @screen-sm) {
footer {
    .footerlinks {
        height : 302px;
		border-bottom : 2px solid @white;
    }
	.footerlinksmenu { 
		padding : 0px;
		margin : 0px;
		width : 100%;
		clear : both;
		height : 300px;

		li { 
			height : auto; 
			display : block; 
			max-width : 100%;  
			text-align : center; 
			width : 100%;
			position : static;

			a { 
				background : url("../img/secondaryLinkBg.png") repeat-x scroll center bottom;
				color : @white;
				border-top : 2px solid @white;
				padding : 10px 0px 20px !important;
				position : static;
				width : 100%;
				height : 60px;

				&:hover { 
					background : url("../img/secondaryLinkBg.png") repeat-x scroll center bottom;
			        border-top: 2px solid @brand-primary-two-dark;
				    height : 60px;
				} 
			}
		}
	}
    .footlink {
        margin-left : auto!important;
        margin-right : auto!important;
    }
}
.fbleft {
    text-align : center!important;
}
.fbright {
    text-align : center!important;
}
}
// Make bottom links only visible on homepage
.page-template-template-homepage-php {
footer {
    .footerlinks {
        display : block;
        visibility : visible;
    }   
}
}

// MAT Footer Links
.matlinksbase {
    background-color : @brand-primary-one-light;
    
    ul {
        list-style-type : none;
		padding : 0px;
		margin : 0px;
		width : 100%;
		clear : both;
		border-bottom : 2px solid @white;

		li { 
			max-width : 100%;  
			text-align : center; 
			width : 100%;

			a { 
				color : @white;
				padding : 10px 0px 10px !important;
				width : 100%;
				display : block;

				&:hover { 
					background-color : @brand-primary-one-lightish;
					text-decoration : none;
				} 
			}
		}
	}  
}