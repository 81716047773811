// -------------------------------------------
// Styles originally set for Homepage, but also contains
// Content Page related content elements (courses, subjects, etc.)
// -------------------------------------------



// -------------------------------------------
// NEWS POSTS
.newshomeposts {
	margin-top : 20px;
	padding-left : 0px;
	padding-right : 0px;
	padding-top : 0px;
	//padding-bottom : 50px;
	//background-color : @brand-primary-one-shade;
	
	transition : all 0.5s;
	
	.nhpintrobox {
		padding : 20px 20px;
		background-color : @brand-primary-one-moretrans;
		color : @white;
		
		h3 {
			.zeropm;
		}
	}
	
	&.darkbg {
		//background-color : @brand-primary-one;
	}
	
	// Content page specific styles
	&.contentpages {
		margin-bottom : 40px;
	}
	
	.nfheader {
		padding-bottom : 10px;
		background-color : @brand-primary-one-trans !important;
		text-align : left;
		
		.nftitle {
			.zeropm;
			
			h3 {
				margin-top : 0px;
				margin-left : 30px;
				padding-top : 20px;
				padding-bottom : 0px;
				color : @white;
				font-size : 2em;
				
				small {
					font-size : 0.5em;
				}
			}
		}
		.nflink {
			.zeropm;
			
			a {
				margin-top : 85px;
				margin-right : 65px;
			}    			
		}
	}
	
	.nfpcontent {
		padding : 0px 0px 0px 0px;
		
		transition : all 0.5s;
		
		h3 {
			margin-left : 20px;
			margin-bottom : 30px;
		}
		
		.nfparticle {
			.zeropm;
			
			transition : all 0.5s;
		
			&.first {
				background-color : @brand-primary-two;
				.newsimage {
					border-color : @brand-primary-two;
				}
			}
			&.second {
				background-color : @brand-primary-four;
				.newsimage {
					border-color : @brand-primary-four;
				}
			}
			&.third {
				background-color : @brand-primary-five;
				.newsimage {
					border-color : @brand-primary-five;
				}
			}
			&.fourth {
				background-color : @brand-primary-six;
				.newsimage {
					border-color : @brand-primary-six;
				}
			}
			&.fifth {
				background-color : @brand-primary-five;
				.newsimage {
					border-color : @brand-primary-five;
				}
			}
			&.sixth {
				background-color : @brand-primary-four;
				.newsimage {
					border-color : @brand-primary-four;
				}
			}

			a {
				display : block;
				
				&:hover {
					text-decoration : none;
					
					.nfpover {
						
						.nfpoverwords {
		    				opacity: 1.0;
		    				filter: alpha(opacity=100);
							background-color : fade(@brand-primary-three-trans, 40%);
						}
					}
				}
				
				.nfpover {
						
					.nfpoverwords {
						position : absolute;
						top : 0px;
						left : 0px;
						//z-index : 10000;
						
						width : 100%;
						//height : 301px;
						font-size : 30px;
						text-align : center;
						padding-top : 100px;
						color : @white;
						background-color : fade(@brand-primary-three-trans, 0%);
						
						transition : all 0.5s;
						
	    				opacity: 0.0;
	    				filter: alpha(opacity=0);
	    				
	    				.glyphicon {
	    					position : relative;
	    					top : 7px;
	    				}
						
					}
				}
			}
			
			.newsimage {
				border : 20px solid;
				
				background-size : cover !important;
	    		background-position : center center !important;
	    		position : relative;
				display : block;
				height : 300px;
				
				
			}
			
			.newswords {
				padding : 0px 0px;
				
				h4 {
					margin-top : 0px;
					margin-bottom : 0px;
					padding : 0px 20px;
    				//font-size : 25px;
    				//line-height : 27px;
    				text-align : left;
    				color : @white;
				}
				.newsdatestamp {
					padding : 10px 20px 5px 20px;
					font-size : 11px;
    				color : @white;
				}
				.newswordscont {
					padding : 5px 20px 10px 20px;
					font-size : 14px;
    				line-height : 16px !important;
    				color : @white !important;
    				height : 110px;
    				overflow-y : hidden;
    				
    				p {
    					padding-top : 0px;
    					font-size : 14px;
	    				line-height : 17px !important;
    				}
				}
			}
			
		}

		&.schoolinfo { //School Information page colour variations
			.nfparticle {
				a {
					&:hover {
						.nfpover {
							.nfpoverwords {
								background-color : fade(@brand-primary-two-trans, 40%);
							}
						}
					}
					
					.nfpover {
						.nfpoverwords {
							background-color : fade(@brand-primary-two-trans, 0%);						
						}
					}
				}
			}
		}

	}
	.oldposts {
		//background-color : @gray-lighter;
		
		h2 {
			.zeropm;
			color : @brand-primary-one !important;
			text-transform : uppercase;
			font-weight : 700;
			font-size : 50px !important;
			text-align : center;
			letter-spacing: 2px;
			margin-bottom : 40px;
			margin-top : 20px;
			padding-top : 40px;
		}
		
		.nfpcontent {
			
			.nfparticle {
				//border : 2px solid @gray-lighter;
				//background-color : @white;
				
				.newswords {
					//background-color : @white;
				}
			}
		}
	}	
	
	
	// Home News Box height values - SEE BELOW FOR ADDITIONAL VALUES
	@homenewsheight-xs : 400px;
	@homenewsheight-sm : 200px;
	@homenewsheight-md : 250px;
	@homenewsheight-lg : 300px;
	@media (min-width: @screen-xs-min) {
	.nfpcontent {
		.nfparticle {
			.newsimage {
				height : @homenewsheight-xs;
			}
			.nfpover {
				min-height : 600px;
				.nfpoverwords {
					height : @homenewsheight-xs;
				}
			}
		}
	}
	} 
	@media (min-width: @screen-sm-min) { 
	.nfpcontent {
		.nfparticle {
			.newsimage {
				height : @homenewsheight-sm;
			}
			.nfpover {
				min-height : 370px;
				.nfpoverwords {
					height : @homenewsheight-sm;
				}
			}
		}
	}
	} 
	@media (min-width: @screen-md-min) { 
	.nfpcontent {
		.nfparticle {
			.newsimage {
				height : @homenewsheight-md;
			}
			.nfpover {
				min-height : 450px;
				.nfpoverwords {
					height : @homenewsheight-md;
				}
			}
		}
	}
	} 
	@media (min-width: @screen-lg-min) { 
	.nfpcontent {
		.nfparticle {
			.newsimage {
				height : @homenewsheight-lg;
			}
			.nfpover {
				min-height : 500px;
				.nfpoverwords {
					height : @homenewsheight-lg;
				}
			}
		}
	}
	}
	
	// Remove side padding on heading for wide screens
	@media (min-width: 1520px) { 
	.nfheader {
		.nftitle {
			h3 {
				//margin-left : 0px;
			}
		}
		.nflink {
			a {
				//margin-right : 0px;
			}    			
		}
	}
	}


	// Subjects listing
	&.subjectslist {
		//border : 2px dotted red;

		.nfparticle {
			a {
				&:hover {
					.nfpover {
						.nfpoverwords {
		    				opacity: 1.0;
		    				filter: alpha(opacity=100);
							background-color : fade(@brand-primary-two-trans, 40%);
						}
					}
				}
				.nfpover {
					.nfpoverwords {
						color : @white;
						background-color : fade(@brand-primary-two-trans, 0%);
					}
				}
			}
		}
	}
	
	// Courses listing
	&.courseslist {
		//border : 2px dotted green;

		.nfparticle {
			a {
				&:hover {
					.nfpover {
						.nfpoverwords {
		    				opacity: 1.0;
		    				filter: alpha(opacity=100);
							background-color : fade(@brand-primary-four-trans, 40%);
						}
					}
				}
				.nfpover {
					.nfpoverwords {
						color : @white;
						background-color : fade(@brand-primary-four-trans, 0%);
					}
				}
			}
		}
	}

	// Subjects & Courses listing common styles
	&.subjectslist, &.courseslist {
		
		
		// Subjects & Courses height values 
		@relatedcontentheight-xs : ceil((@homenewsheight-xs * 0.90));
		@relatedcontentheight-sm : ceil((@homenewsheight-sm * 0.80));
		@relatedcontentheight-md : ceil((@homenewsheight-md * 0.70));
		@relatedcontentheight-lg : ceil((@homenewsheight-lg * 0.70));	
		
		@media (min-width: @screen-xs-min) {
		.nfpcontent {
			.nfparticle {
				.newsimage {
					height : @relatedcontentheight-xs;
				}
				.nfpover {
					min-height : @relatedcontentheight-xs;
					.nfpoverwords {
						height : @relatedcontentheight-xs;
					}
				}
			}
		}
		} 
		@media (min-width: @screen-sm-min) { 
		.nfpcontent {
			.nfparticle {
				.newsimage {
					height : @relatedcontentheight-sm;
				}
				.nfpover {
					min-height : @relatedcontentheight-sm;
					.nfpoverwords {
						height : @relatedcontentheight-sm;
					}
				}
			}
		}
		} 
		@media (min-width: @screen-md-min) { 
		.nfpcontent {
			.nfparticle {
				.newsimage {
					height : @relatedcontentheight-md;
				}
				.nfpover {
					min-height : @relatedcontentheight-md;
					.nfpoverwords {
						height : @relatedcontentheight-md;
					}
				}
			}
		}
		} 
		@media (min-width: @screen-lg-min) { 
		.nfpcontent {
			.nfparticle {
				.newsimage {
					height : @relatedcontentheight-lg;
				}
				.nfpover {
					min-height : @relatedcontentheight-lg;
					.nfpoverwords {
						height : @relatedcontentheight-lg;
					}
				}
			}
		}
		}
		
	}

	// FOR PAGES WHERE DATE AND EXCERPT ARE SET TO HIDDEN
	&.contentpages {
		&.hideinfo {
			
			.newsdatestamp {
				display : none;
				visibility : hidden;
			}
			.newswordscont {
				display : none; 
				visibility : hidden;
			}
			
			// Home News Box height values - SEE ABOVE FOR INITIAL VALUES
			@media (min-width: @screen-xs-min) {
			.nfpcontent {
				.nfparticle {
					.nfpover {
						min-height : 450px;
					}
				}
			}
			} 
			@media (min-width: @screen-sm-min) { 
			.nfpcontent {
				.nfparticle {
					.nfpover {
						min-height : 250px;
					}
				}
			}
			} 
			@media (min-width: @screen-md-min) { 
			.nfpcontent {
				.nfparticle {
					.nfpover {
						min-height : 300px;
					}
				}
			}
			} 
			@media (min-width: @screen-lg-min) { 
			.nfpcontent {
				.nfparticle {
					.nfpover {
						min-height : 350px;
					}
				}
			}
			}
		}
	}
}



// -------------------------------------------
// SAFEGUARDING TEMPLATE
.page-template-template-safeguarding {
	
	.rhscontent {
		
		.well {
			margin-bottom : 10px;
			
			&.meetthesenco {
				
				.listthumb {
					width : 100px;
					height : 150px;
				}
				p {
					line-height: @font-size-base !important;
				}
			}
		}
	}
}


// -------------------------------------------
// NEWS POSTS