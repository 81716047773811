// Temped colours

@brand-primary-sfvoe : #2D396F; //#AD8300;
@brand-primary-bg-sfvoe : #D5D7E2; //#FFE9A6;
@brand-primary-bg-light-sfvoe : fade(@brand-primary-bg-sfvoe, 40%);

@brand-success-sfvoe : #004811; //#3c763d;
@brand-success-bg-sfvoe : #CCDACF; //#dff0d8;
@brand-success-bg-light-sfvoe : fade(@brand-success-bg-sfvoe, 60%);

@brand-info-sfvoe : #8B9AD4; //#31708f;
@brand-info-bg-sfvoe : #E8EBF6; //#d9edf7;
@brand-info-bg-light-sfvoe : fade(@brand-info-bg-sfvoe, 40%);

@brand-warning-sfvoe : #7DB2B8; //#8a6d3b;
@brand-warning-bg-sfvoe : #E5F0F1; //#fcf8e3;
@brand-warning-bg-light-sfvoe : fade(@brand-warning-bg-sfvoe, 40%);

@brand-danger-sfvoe : #D9534F; //#a94442;
@brand-danger-bg-sfvoe : #F7DDDC; //#f2dede;
@brand-danger-bg-light-sfvoe : fade(@brand-danger-bg-sfvoe, 40%);




// Open Evening Font

// Museo Sans Font
@font-face {
    font-family: 'MuseoSans100';
    src: url('../fonts/museosans/MuseoSans-100.eot');
    src: url('../fonts/museosans/MuseoSans-100.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museosans/MuseoSans-100.woff2') format('woff2'),
        url('../fonts/museosans/MuseoSans-100.woff') format('woff'),
        url('../fonts/museosans/MuseoSans-100.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans300';
    src: url('../fonts/museosans/MuseoSans-300.eot');
    src: url('../fonts/museosans/MuseoSans-300.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museosans/MuseoSans-300.woff2') format('woff2'),
        url('../fonts/museosans/MuseoSans-300.woff') format('woff'),
        url('../fonts/museosans/MuseoSans-300.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans500';
    src: url('../fonts/museosans/MuseoSans-500.eot');
    src: url('../fonts/museosans/MuseoSans-500.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museosans/MuseoSans-500.woff2') format('woff2'),
        url('../fonts/museosans/MuseoSans-500.woff') format('woff'),
        url('../fonts/museosans/MuseoSans-500.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans700';
    src: url('../fonts/museosans/MuseoSans-700.eot');
    src: url('../fonts/museosans/MuseoSans-700.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museosans/MuseoSans-700.woff2') format('woff2'),
        url('../fonts/museosans/MuseoSans-700.woff') format('woff'),
        url('../fonts/museosans/MuseoSans-700.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans900';
    src: url('../fonts/museosans/MuseoSans-900.eot');
    src: url('../fonts/museosans/MuseoSans-900.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museosans/MuseoSans-900.woff2') format('woff2'),
        url('../fonts/museosans/MuseoSans-900.woff') format('woff'),
        url('../fonts/museosans/MuseoSans-900.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@MuseoSans100:  MuseoSans100, Open Sans, sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
@MuseoSans300:  MuseoSans300, Open Sans, sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
@MuseoSans500:  MuseoSans500, Open Sans, sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
@MuseoSans700:  MuseoSans700, Open Sans, sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
@MuseoSans900:  MuseoSans900, Open Sans, sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;



// Styles for campaign
.sixth-form-entry-2021,
.applying-to-fallibroome-sixth-form
{
	
	// Content font
	.content {
		font-family : @MuseoSans300;
	}

	// Remove spacing under header image
	.breadcrumb { display : none; visibility : hidden; }
	
	// Well BG colour
	.bgtransoverlay .well {
		background-color : #FFFFFF !important;
		border : 0px none;
		
		-webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
	}
	
	// Main header styling
	.page-header {
		padding-bottom : 0px !important;
	}
	.page-header h2 {
 		margin-top : 0px;
 		padding : 20px 0px 30px 0px;
 		font-size : 50px;
	}
	.page-header h2 small {
 		color : #FFFFFF;
	}
	.sfvoe_subtitle {
		font-family : @MuseoSans500;
		font-size : 30px;
	}

	// Page nav
	.vodtmenu {
		
		li {
			background-color : transparent !important;
			
			&.active {
				
				a {
					background-color : transparent !important;
				}
			}
		}
		
		.vodtmenulink {
			text-align : center;
			
			a {
				padding : 30px 5px;
				width : 100%;
				height : 100%;
				display : block;
				font-family : @MuseoSans500 !important;
				font-size : 20px;
				background-color : @brand-primary-bg-sfvoe;
				color : @brand-primary-sfvoe !important;
				
				.basictransition;
				
				&:hover {
					text-decoration : none;
					background-color : @brand-primary-bg-light-sfvoe;
				}
			}

			&:nth-of-type(even) {
			a {
				background-color : @brand-info-bg-sfvoe;
				color : @brand-primary-sfvoe !important;
			
				&:hover {
					background-color : @brand-info-bg-light-sfvoe;
				}
			}
			}

			&.vodtapply {

				a {
					background-color : @brand-danger-bg-sfvoe;
					color : @brand-danger-sfvoe !important;
				
					&:hover {
						background-color : @brand-danger-bg-light-sfvoe;
					}
				}
			}

		}
	}
	
	// Panels
	.panel {
		
		&.noborder {
			border : 0px none !important;
		}
		
		.panel-heading {
			text-align : left;
			
			.panel-title {
				padding : 20px 0px 10px 0px;
				font-weight : normal !important;
				font-size : 50px !important;
			}
		}
		
		.panel-body {
	
				.well {
					border : 1px solid @brand-primary-bg-sfvoe;
					//background-color : @brand-primary-bg-light-sfvoe !important;
					background-color :#ebebeb !important;
					
					h4 {
						font-size : @font-size-h3;
						font-family : @MuseoSans500;
					}
				}			
		}

		&.panel-odd {
			border : 1px solid @brand-primary-bg-sfvoe;

			.panel-heading {
				background-color : @brand-primary-bg-sfvoe;
				
				.panel-title {
					color : @brand-primary-sfvoe !important;
				}
			}
			.panel-body {
				background-color : @white;
			}
			.panel-footer {
				background-color : @brand-primary-bg-sfvoe;
			}
		}

		&.panel-even {
			border : 1px solid @brand-info-bg-sfvoe;

			.panel-heading {
				background-color : @brand-info-bg-sfvoe;
				
				.panel-title {
					color : @brand-primary-sfvoe !important;
				}
			}
			.panel-body {
				background-color : @white;
			}
			.panel-footer {
				background-color : @brand-info-bg-sfvoe;
			}
		}
	
		&.panel-danger {

			.panel-heading {
				//border-bottom : 1px solid @brand-danger-sfvoe;
				
				.panel-title {
					color : @brand-danger-sfvoe !important;
				}
			}
			.panel-body {
				background-color : @brand-danger-bg-light-sfvoe;
				
				h3 {
					color : @brand-danger-sfvoe !important;
				}
			}
			.panel-footer {
				background-color : @brand-danger-bg-sfvoe;
				border-top : 0px none;
				
				.btn-danger {
					background-color : @brand-danger-bg-light-sfvoe;
					color : @brand-danger-sfvoe !important;
				}
				.glyphicon {
					color : @brand-danger-sfvoe !important;
				}
			}
		}
		
		// Course info layout
		&.courses {
			
			.btn-xs {
				font-size : @font-size-large !important;
				color : @white !important;
			}
		}
	}
	
	// Red Button styles
	.btn-danger {
		color : #FFFFFF !important; 
		margin-top : 10px !important; 
		margin-bottom : 10px !important;
	}
	
	// -------------------------
	// START PAGE
	
	// Top info boxes

	
	// -------------------------
	// APPLY PAGE
	
	// Top info boxes
	.sfvoeapplytopboxes {
		
		
		&.boxone {
			
		}
		&.boxtwo {
			
		}
		&.boxthree {
			
		}
		
		.well {
			 background-color : @brand-primary-bg-light-sfvoe !important;
			 text-align : left;
		}
		
		// Title Icon
		.sfvoeatbheadicon {
			height : 120px;
			width : 120px;
			border-radius : 60px;
			padding-top : 20px;
			margin-bottom : 20px;
			background-color : @brand-primary-bg-sfvoe;
			color : @white !important;
			text-align : center;
			font-size : 70px;
			
			.glyphicon {
				color : @white !important;
			}
		}
		
		// Title Words
		.sfvoeatbhead {
			margin-top : 0px;
			margin-bottom : 20px;
			padding-bottom : 20px;
			font-family : @MuseoSans500;
			font-size : @font-size-h2;			
			border-bottom : 2px solid @brand-primary-bg-sfvoe;
		}
	}
	
	// Step-by-step guide
	.sfvoebigctitle {
		border-top : 2px solid @brand-primary-bg-sfvoe;
		padding-top : 20px;
		padding-bottom : 20px;
		margin-top : 20px;
		
		h2 {
			//color : @white;
			font-family : @MuseoSans500;
			font-size : @font-size-h1;
		}
	}
	
	.sfwatimebox { 
		/*min-height : 450px;*/ 
		
		.well {
			background-color : #FFFFFF; 
			text-align : left; 
			padding : 0px; 
			border-radius : 0px; 
			border: 0px none; 
			border-bottom : 2px solid #DDDDDD;
		}
		.panel-body {
			background-color : #FFFFFF; 
			color : #000000; 
			border-bottom : 0px none !important;
		}
		
		// Headings
		.panel-heading {
            padding : 0px 0px;
            background-color : @brand-primary-sfvoe;
			position : relative;
			border-bottom : 0px none;
			
			.shadeoverlay {
    			background-color : @brand-primary-bg-light-sfvoe;
				height : 100%;
				white-space: nowrap;
				
				&.sostepone {
					width : 100%;
				}
				&.sosteptwo {
					width : 87.5%;
				}
				&.sostepthree {
					width : 75%;
				}
				&.sostepfour {
					width : 62.5%;
				}
				&.sostepfive {
					width : 50%;
				}
				&.sostepsix {
					width : 37.5%;
				}
				&.sostepseven {
					width : 25%;
				}
				&.sostepeight {
					width : 12.5%;
				}
				&.sostepnine {
					width : 0%;
				}
			}
	
			.panel-title {
				font-size : @font-size-h3 !important;
			}
			
			h3 {
				color : @white !important;
				padding-top : 0px;
				padding : 10px 10px !important;
				text-align : left;
				font-family : @MuseoSans500;
				//z-index : 10000;
				
				
				//position : absolute;
				//top : 0px;
				//left : 0px;
				//border : 2px dotted red;
				
				small {
					color : @white;
					font-family : @MuseoSans700;
				}
			}
		}
	}
	
	
} // END .sixth-form-entry-2021, .applying-to-fallibroome-sixth-form
