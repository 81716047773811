
// Controls the triangular info panels on the homepage

.fadcad {
	padding-top : 0px;
    width: 0;
    height: 0;
    line-height: 0px;
    border-style: solid;
    z-index : 20000;
    
	-webkit-transition : all 0.2s ease-in-out 0.2s;
	-moz-transition : all 0.1s ease-in-out 0s;  
	-ms-transition : all 0.1s ease-in-out 0s; 
	-o-transition : all 0.1s ease-in-out 0s; 
	transition : all 0.1s ease-in-out 0s; 
	
	&.perfarts {
	    padding-right : 4px;
        border-width: 0 120px 120px 0;
        border-color: transparent #a8a677 transparent transparent;
        _border-color: #000000 #a8a677 #000000 #000000;
        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    
        position : absolute; 
        right : 0px; 
        top : 0px;
        
        &:hover {
            border-width: 0 122px 122px 0;
            padding-top : 0px;
            padding-right : 5px;
        }
        
        .innerbox {
            text-align : right;
        }
	}
	
	&.iinfou {
	    padding-left : 4px;
        border-width: 120px 120px 0 0;
        border-color: #a8a677 transparent transparent transparent;
        _border-color: #a8a677 #000000 #000000 #000000;
        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
        
        position : absolute; 
        left : -5px; 
        top : 0px; 
        
        &:hover {
            border-width: 122px 122px 0 0;
            padding-top : 0px;
            padding-left : 5px;
        }
        
        .innerbox {
            text-align : left;
            margin-left : 5px;
        }
	}
    
    .innerbox {
        height : 120px;
        width : 120px;
        //display : block; 
        
        a {
            padding-top : 0px;
            color : @white;
            //height : 120px;
            //width : 120px;
            //display : block;
            font-size : 13px;
            line-height : 16px;
    
        	-webkit-transition : all 0.2s ease-in-out 0.2s;
        	-moz-transition : all 0.1s ease-in-out 0s;  
        	-ms-transition : all 0.1s ease-in-out 0s; 
        	-o-transition : all 0.1s ease-in-out 0s; 
        	transition : all 0.1s ease-in-out 0s; 
            
            &:hover {
                text-decoration : none;
            }
        }
    }
}