// -------------------------------------------
// See ContentArea-SpecialBoxes.less for dynamic content area styling
// -------------------------------------------

// Page content width  
.contentinner {
	padding : 0px 0px 0px 0px;
	margin : 0px auto 0px auto;
	max-width : 1500px;
	background-color : @white; //Commented out whilst working on TYear Group page to give tranparent BG between menu and content
}
.single-yeargroup 
{
	.contentinner {
		background-color : transparent;
	}
}

.contentarea {
	padding : 40px 40px 40px 40px;
	background-color : @white;
	min-height : 400px;
	
	&.gencontentstyles {
		
	}
	
	a {
		
	}
}


// -------------------------------------------
// Right hand elements
.well {
	margin-bottom : 0px;
	border-style : none;
	box-shadow : none;
	//background-color : @brand-primary-one-light;
	
	&.righthandimage {
		border : 0px solid @brand-primary-one-trans;
		//background-color : @brand-primary-one-trans;
		border-radius : 0px;
		border-style : none;
	}
	
	&.righthandcarousel {
		border : 0px solid @brand-primary-one-trans;
		//background-color :  @brand-primary-one-trans;
		border-radius : 0px;
		border-style : none;
		
		.cycloneslider-template-standard .cycloneslider-slide {
			//background : @brand-primary-one-trans;
		}
	}
}


// -------------------------------------------
// Page Elements
.homespotlist {
    border-bottom : 1px solid @white;
    padding-left : 0px;
    
    a { 
        display : block;
        
        &:hover {
            text-decoration : none;
        }
    }
    
    li {
        padding : 10px 20px 10px 20px;
        list-style-type : none;
        background-color : darken(@gray-lighter, 5%);
        
        //-webkit-transition : background-color 0.75s; /* For Safari 3.1 to 6.0 */
        //transition : background-color 0.75s;
        //transition-timing-function : ease-in-out;
        
        &:nth-child(odd){
            background-color : @gray-lighter;
        }
        
        &:hover {
            background-color : initial;   
        }
        
        .listthumb {
             margin-left : 10px;
             position : relative;
             right : 5px;
             background-position : center;
             width: 80px;
             height: 80px;
             float : right;
             
             background-size : cover;
             
             //-webkit-border-radius: 38px;
             //-moz-border-radius: 38px;
             //border-radius: 38px;
        }
        .listwords {
            padding-left : 5px;
        }
    }
}


// -------------------------------------------
// Hide WP Related Posts
#jp-relatedposts {
	display : none;
	visibility : hidden;
}


// -------------------------------------------
// PAGE VARIANTS

// post_iFrame
.iframelayout {
	//border : 2px solid @gray-lighter;
}



// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// TEMPLATE - Vacancies
.page-template-template-vacanies {
	
	 .contentarea {
	 	//padding-bottom : 0px;
	 }
}



// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Common Page content sections
.relatedcontentbox {
	//border : 1px dotted red;

// Staff lists found in StaffListing.less
	
}

// Back button on Curriculum pages (initially)
.undertitlebackbutton {
	background-color : @brand-primary-one;
	
	button {
		.border-zero;
	}
}