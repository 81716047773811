    /* Checklist */
    .ofstedchecklisttable {border : 1px solid #DDDDDD; font-size : 11px;}
    .ofstedchecklisttable tr:nth-child(even) {background: rgba(0,0,0,0.10);}
    .ofstedchecklisttable th {padding : 3px 10px 3px 3px;}
    .ofstedchecklisttable td {width : 20px; height : 10px; display : table-cell;}
    .ofstedchecklisttable td.checked {background-color : #00FF00;}
    .ofstedchecklisttable td.unchecked {background-color : #FF0000;}
    .ofstedchecklisttable td.conditional {background-color : #FF7A4D;}

    /* General Styles */
    .textred {color : #FF0000;}
    .textorange {color : #FF7A4D;}

    /* Notes Box */
    .ofstednotes {margin-top : 20px; padding : 3px 3px; margin-bottom : 5px; font-size : 12px; line-height : 16px;}
    .ofstednotes p {margin-bottom : 5px; font-size : 12px; line-height : 16px;}

    /* Tab Menu */
    .mainroitabmenu {text-align : center;}
    .mainroitabmenu li {margin-bottom : 3px;}
    .mainroitabmenu li a {border : 1px solid #DDDDDD;}

    /* Term Dates Table */
    .termdatestable { border: 1px solid #DDDDDD; background-color : #FFFFFF; width : 100%; }
    .termdatestable th { padding : 5px 10px; background-color : #DDDDDD; border-top : 1px solid #EEEEEE; }
    .termdatestable th.termheading { background-color : #EEEEEE; }
    .termdatestable td { padding : 5px 10px; border-top : 1px solid #DDDDDD; }