.page-template-template-communityLanding {
    

    
	// Totaliser
    .totaliser {
        background-color : @brand-primary-one;
        color : @white;
        padding-top : 10px;
        padding-bottom : 10px;
        
        .totaliser-total {
            font-size : @font-size-h3 !important;
            text-transform : uppercase;
            margin-bottom : 3px !important;
        }
    }    
    
    // Events box
    .communityevents {
    	padding-left : 0px;
    	padding-right : 0px;
    	margin-bottom : 20px;
    
	    .eventslistbox {
            .zeropm;
            
	        h3 {
				padding-top : 15px;
				margin-top : 0px;
				margin-left : auto;
				margin-right : auto;
				margin-bottom : 20px;
				padding-bottom : 10px;
				//border-bottom : 1px solid @gray-lighter;
				border-top : 1px solid @gray-lighter;
				text-align : center;
				text-transform : uppercase;
				font-size : @font-size-h2 !important;
	        }
            
            // Event button
            .eventslistboxlistlink {
                .zeropm;
                margin-bottom : 10px;

                -webkit-transition: all 0.2s ease-in-out 0s;
                -moz-transition: all 0.2s ease-in-out 0s;
                -ms-transition: all 0.2s ease-in-out 0s;
                -o-transition: all 0.2s ease-in-out 0s;
                transition: all 0.2s ease-in-out 0s;
                
                -webkit-box-shadow : 0px 0px 1px rgba(0,0,0,0.5);
                -moz-box-shadow : 0px 0px 1px rgba(0,0,0,0.5);
                box-shadow : 0px 0px 1px rgba(0,0,0,0.5);
                
                &:hover {
                    text-decoration : none !important;
                    background-color : @black-trans;
                    
                    -webkit-box-shadow : 0px 0px 4px rgba(0,0,0,0.8);
                    -moz-box-shadow : 0px 0px 4px rgba(0,0,0,0.8);
                    box-shadow : 0px 0px 4px rgba(0,0,0,0.8);
                }
                
                a {
                    &:hover {
                        text-decoration : none !important;
                    }
                }
                
                .eventslistboxlistlinkinner {
                    .zeropm;
                    padding : 15px 10px;
                    background-color : @black-trans;
                    color : @white;
                    
                    -webkit-transition: all 0.2s ease-in-out 0s;
                    -moz-transition: all 0.2s ease-in-out 0s;
                    -ms-transition: all 0.2s ease-in-out 0s;
                    -o-transition: all 0.2s ease-in-out 0s;
                    transition: all 0.2s ease-in-out 0s;
                    
                    position : absolute;
                    bottom : 10px;
                    
                    .eventtitle {
                        font-weight : bold;
                    }
                    .date {
                        font-size : @font-size-smaller;
                    }
                    
                    &:hover {
                        background-color : @black-lesstrans;
                        text-decoration : none !important;
                    }
                }
            }
            
            // Event Modal
            .modal {
                
                .modal-header {
                    height : 50px;
                    background-color : @gray-lighter;
                    
                    &.withbg {
                        height : 500px !important;
                    }
                    
                    .post-edit-link {
                        float : left;
                        margin-right : 10px;
                    }
                }
                .modal-body {
                    
                    h1 {
                        text-align : center;
                        font-size : 45px !important;
                        line-height : 40px !important;
                        font-weight : bold;
                        color : @brand-primary-one;
                        text-shadow: 0px 0px 1px @brand-primary-one;
                    }
                    .date {
                        margin-top : 10px;
                        text-align : center;
                        font-size : 14px;
                        font-weight : bold;
                        color : @gray;
                        text-shadow: 0px 0px 1px @gray;                           
                    }
                    .listwords {
                        padding-top : 20px;
                        text-align: justify;
                        
                        .totalraised {
                            padding : 30px;
                            float : right;
                            margin-left : 20px;
                            margin-bottom : 20px;
                            
                            height : 200px;
                            width : 200px;
                            background-image : url(../img/BG-Rosette.png);
                            
                            p {
                                padding-top : 30px;
                                width : 100%;
                                text-align : center;
                                font-size : @font-size-h2;
                                font-weight : bold;
                                color : @white;
                                text-shadow: 0px 0px 1px @white;
                                
                                -ms-transform: rotate(7deg); /* IE 9 */
                                -webkit-transform: rotate(7deg); /* Chrome, Safari, Opera */
                                transform: rotate(7deg);
                                
                                small {
                                    color : @white-trans;
                                    text-shadow: 0px 0px 1px @white-trans;
                                    font-size : 14px;
                                }
                            }
                        }
                        .charityraisedfor {
                            padding : 10px;
                            background-color : @gray-lighter;
                            border-radius : 5px;
                            margin-bottom : 30px;
                            
                            small {
                                font-size : 12px !important;
                                font-weight : bold;
                                color : @brand-primary-one;
                                text-shadow: 0px 0px 1px @brand-primary-one;
                            }
                        }
                    }
                }
                .modal-footer {
                    background-color : @gray-lighter;
                }
            }
	        
	        // Other events modal
	        #myModalprevcharity {
	            
	            h1 {
	                text-align : center;
	                font-size : 45px !important;
	                line-height : 40px !important;
	                font-weight : bold;
	                color : @brand-primary-one;
	                text-shadow: 0px 0px 1px @brand-primary-one;
	            }
	            
	            .preveventslistboxlist {
	                .zeropm;
	                list-style-type : none;
	                
	                li {
	                    margin : 0px 0px 10px 0px;
	                    padding : 10px 10px;
	                    border : 1px solid @gray-lighter;
	                    border-radius : 3px;
	                    .gradient-lightgrey-to-white;
	                    
	                    .headingbox {
	                        float : left;
	                    
	                        h2 {
	                            margin-top : 10px;
	                            font-size : 30px !important;
	                            line-height : 25px !important;
	                            font-weight : bold;
	                            color : @brand-primary-one;
	                            text-shadow: 0px 0px 1px @brand-primary-one;
	                        }
	                    }
	                    
	                    .totalraised {
	                        padding : 5px;
	                        float : right;
	                        margin-left : 5px;
	                        margin-bottom : 5px;
	                        
	                        height : 100px;
	                        width : 100px;
	                        background-image : url(../img/BG-Rosette-sm.png);
	                        
	                        p {
	                            padding-top : 0px;
	                            width : 100%;
	                            text-align : center;
	                            font-size : @font-size-h4;
	                            font-weight : bold;
	                            color : @white;
	                            text-shadow: 0px 0px 1px @white;
	                            
	                            -ms-transform: rotate(7deg); /* IE 9 */
	                            -webkit-transform: rotate(7deg); /* Chrome, Safari, Opera */
	                            transform: rotate(7deg);
	                            
	                            small {
	                                color : @white-trans;
	                                text-shadow: 0px 0px 1px @white-trans;
	                                font-size : 12px;
	                            }
	                        }
	                    }
	                }
	            }
	        }
	    }
    
    }
    
    // Raising funds for... box
    .raisefunds {
        text-align : center;
        padding-top : 15px;
        margin-left : auto;
        margin-right : auto;
        margin-bottom : 20px;
        padding-bottom : 10px;
        border-bottom : 1px solid @gray-lighter;
        border-top : 1px solid @gray-lighter;
        
        text-transform : uppercase;
        
        h3 {
        	margin-top : 10px;
        	text-transform : uppercase;
        	font-size : 16px;
        }
        
        span {
            font-size : 12px;
            font-weight : bold;
            color : @brand-primary-one;
            text-shadow: 0px 0px 1px @brand-primary-one;
            display : inline-block;
            
            position : relative;
            top : -22px;
        }
        
        .charitylogothisyear.img-responsive {
        	display : inline-block;
        	width : auto;
        }
        
        ul {
            margin-left : auto;
            margin-right : auto;
            list-style-type : none;
            
            li {
                display : inline-block;
                margin-right : 20px;
            }
        }
    }
    
    // Runway on the Runway box
    @runwaypurple : #76004E;
    @runwaypurple-light : lighten(@runwaypurple, 5%);
    .runway {
        padding-top : 0px;
        margin-top : 10px !important;
        
        h3 {
            color : @runwaypurple;
            text-shadow: 0px 0px 1px @runwaypurple;
            font-size : 20px;
            
            img {            
                margin-left: auto;
                margin-right: auto;
            }
        }
        .intro {
            margin-top : 20px;
            margin-bottom : 20px;
            color : @runwaypurple;
            text-shadow: 0px 0px 1px @runwaypurple;
            font-size : 12px;
            line-height : 14px;
            text-align : center;
            
            p {
                all : inherit;
            }
        }
        .btn-primary {
            background-color : @runwaypurple;
            border : 1px solid @runwaypurple;
            
            &:hover {
                background-color : @runwaypurple-light;
            }
        }
    }
    
    // Community Leaders box
    .communityleaders {
        padding : 0px; 
        margin-bottom : 20px;
        margin-top : 0px;
        
        .communityleaderslist {
            .zeropm;
            list-style-type : none;
            
            li {
                padding : 10px;
                
                .listthumb {
                    background-size : cover;
                    width : 76px;
                    height : 76px;
                    float : right;
                    margin-left : 20px;
                    //margin-top : 15px !important;
                     
                    border-radius: 38px;
                    -webkit-border-radius: 38px;
                    -moz-border-radius: 38px;
                }
                .listwords {
                	padding-top : 20px;
                	
                	.commancolour {
                		text-transform : uppercase;
                		font-size : 10px;
                		white-space : nowrap;
                	}
                	h4 {
                		margin-top : 3px;
                		text-transform: uppercase;
                		font-size : @font-size-h5;
                		white-space : nowrap;
                	}
                }
                .emaillink {
                    color : @white;
                    
		            .tooltip {
		            	border : 5px solid red;
		            }
                    
                    &:hover {
                        color : @white-trans;
                    }
                }
                
                &.Redbox {
                    color : @white !important;
                    background-color : @comred !important;
                    
                }
                &.Greenbox {
                    color : @white !important;
                    background-color : @comgreen !important;
                    
                }
                &.Bluebox {
                    color : @white !important;
                    background-color : @comblue !important;
                    
                }
                &.Yellowbox {
                    color : @gray-dark;
                    background-color : @comyellow !important;
                    
                    a {
                        color : @gray-dark;
                        
                        &:hover {
                            color : @gray-darker;
                        }
                    }
                }
            }
        }
    }
    
    .col-xs-12.infosection.feedsinfoarea {
    	padding-left : 0px;
    	padding-right : 0px;
    	margin-bottom : 20px;
    }
    
    // Instragram area
    #sbi_load {
    	
	    .sbi_load_btn {
	    	visibility : hidden;
	    	display : none !important;
	    }
	    
	    .sbi_follow_btn {
	    	margin-left : 0px !important;
	    	
	    	a {
		    	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffff00+0,ff0000+33,ff00ff+66,0000ff+100 */
				background: #ffff00; /* Old browsers */
				background: -moz-linear-gradient(45deg, #ffff00 0%, #ff0000 33%, #ff00ff 66%, #0000ff 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(45deg, #ffff00 0%,#ff0000 33%,#ff00ff 66%,#0000ff 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(45deg, #ffff00 0%,#ff0000 33%,#ff00ff 66%,#0000ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffff00', endColorstr='#0000ff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
				
				font-size : 0px;
				
				i {
					visibility : visible;
					font-size : 60px !important;
					font-weight : normal !important;
					margin-left : 7px;
				}
	    	}
	    }
    }
}



// -------------------------------------------------------------

// Community Leaders boxes - STAFF LIST PAGE
// Copied from above to bring out of template-specific reference

.communityleaders {
	
	.Redbox {
	    color : @white !important;
	    background-color : @comred !important;
	    
	}
	.Greenbox {
	    color : @white !important;
	    background-color : @comgreen !important;
	    
	}
	.Bluebox {
	    color : @white !important;
	    background-color : @comblue !important;
	    
	}
	.Yellowbox {
	    color : @gray-dark;
	    background-color : @comyellow !important;
	    
	    a {
	        color : @gray-dark;
	        
	        &:hover {
	            color : @gray-darker;
	        }
	    }
	}
	
	.ypmeetlm {
		.panel-heading {
			background-color: inherit !important;
			color: inherit !important;
		}
		.panel-footer {
			background-color: inherit !important;
			color: inherit !important;
		}
	}
	
}

.communityleaders {
    padding : 0px; 
    margin-bottom : 20px;
    margin-top : 0px;
    
    .communityleaderslist {
        .zeropm;
        list-style-type : none;
        
        li {
            padding : 10px;
            
            .listthumb {
                background-size : cover;
                width : 76px;
                height : 76px;
                float : right;
                margin-left : 20px;
                //margin-top : 15px !important;
                 
                border-radius: 38px;
                -webkit-border-radius: 38px;
                -moz-border-radius: 38px;
            }
            .listwords {
            	padding-top : 20px;
            	
            	.commancolour {
            		text-transform : uppercase;
            		font-size : 10px;
            		white-space : nowrap;
            	}
            	h4 {
            		margin-top : 3px;
            		text-transform: uppercase;
            		font-size : @font-size-h5;
            		white-space : nowrap;
            	}
            }
            .emaillink {
                color : @white;
                
	            .tooltip {
	            	border : 5px solid red;
	            }
                
                &:hover {
                    color : @white-trans;
                }
            }
            
            &.Redbox {
                color : @white !important;
                background-color : @comred !important;
                
            }
            &.Greenbox {
                color : @white !important;
                background-color : @comgreen !important;
                
            }
            &.Bluebox {
                color : @white !important;
                background-color : @comblue !important;
                
            }
            &.Yellowbox {
                color : @gray-dark;
                background-color : @comyellow !important;
                
                a {
                    color : @gray-dark;
                    
                    &:hover {
                        color : @gray-darker;
                    }
                }
            }
        }
    }
}