.fallisportsenv {
    
    .homecarousel {
    	height : 500px !important;
        
        .carousel-inner {
            
            .item {
                height : 650px !important;
                background-position : center top !important;
            }
        }
    }
    
    .sixthformlayout {
        
        &.fallisports {
            margin-top : 50px;
        }
    }
}