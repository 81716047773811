// -------------------------------------------
// See ContentArea-SpecialBoxes.less for dynamic content area styling
// -------------------------------------------



// Z-index variable to set level of video and slideshow
// Set the Z-index of the .homecarousel layer and use variable to set z-index of other carousel elements
@z-index : -500; 


// -------------------------------------------
// MEDIA ELEMENTS

// Fullscreen video
#myVideo {
	position : fixed;
	right : 0;
	bottom : 0;
	min-width : 100%;
	min-height : 100%;
	z-index : @z-index;
}
.video-container {
	position : fixed;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
	z-index : @z-index;
}
video {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    min-height: 50%;
    min-width: 50%;
}
.videocontrols {
	text-align : center;
	//z-index: 50000;
	z-index : -(round(@z-index*10));
	position : relative;
	top : -100px;
	width : 100%;
	
	button {
		border : 0px none;
		background-color : transparent;
		font-weight : 700;
		color : @white-trans;
		position : absolute;
		top : -10px;
		left : 10px;
		
		.glyphicon {
			position : relative;
			top : 10px;
			font-size : 40px;
		}
	}
}
// Page spacer for video
.vidheight {
	height : 100vh;
}
// Video down button
.downbutton {
	position : absolute;
	top : -40px;
	
	width : 100%;
	height : 100vh;
	text-align : center;
	display : table;
	
	p {
		display : table-cell;
		vertical-align : bottom;
		width : 100%;
		height : 100%;
		
		a {
			font-size: 70px;
			color: #FFFFFF;
			.transition-header-zeropointone;
					
			&:hover {
				color: rgba(255, 255, 255, 0.5);
			}
		}
	}
}

// Remove gradient from media section
.home {
	.main {
		//.gradient-contentbgbackground;
		background : none;
	}
}



// -------------------------------------------
// SLIDESHOW 

// Top Layout
#homecarousel {
	
}
.homecarousel {
    position : relative;
    //height : 80vh;
	//position : fixed !important;
	//position : absolute;
	top : 0;
	right : 0;
	bottom : 0;
	left : 0;
	background-size : cover !important;
	
	min-width : 100%;
	min-height : 100%;
	
	//z-index : @z-index;
	
	&.contentversion {
		margin-top : 20px;
	}
    
    .carousel-inner {
        
        .item {
           background-attachment : fixed !important;
           background-size : cover !important;
           background-position : center center !important;
           height : 100vh;
        }
    }
	.carousel-indicators {
		display : none;
		visibility : hidden;
	}
}

// Carousel elements
.carousel-control {
	
}
.carousel-indicators {
    display : none;
    visibility : hidden;
}

// Slide content
.innertransbg {
	height : 100vh;
	display : table;
}	
.carousel-caption {
    //position : relative;
    //bottom : 200px;
    //bottom : 30vh;
    //height : 100vh;
    text-shadow : 0px 0px 7px rgba(0,0,0,0.9);
    
	display: table-cell;
	vertical-align: middle;
    
    h4 {
		
		small {
			color : @brand-primary-two-lightest;
		}
    }
    p {
    	margin-top : 20px;
		color : @brand-primary-two-lightest;
    }
}

/*
@media only screen and (min-width: 0px) and (max-width: @screen-xs) {
.carousel-caption {
	h4 {
		font-size : 3.5em;
	}
	p {
		margin-top : 10px;
		font-size : 2em;
	}
}
}
@media only screen and (min-width: @screen-xs) and (max-width: @screen-sm) {
.carousel-caption {
	h4 {
		font-size : 4em;
	}
	p {
		margin-top : 10px;
		font-size : 2em;
	}	
}
}
@media only screen and (min-width: @screen-sm) and (max-width: @screen-md) {
.carousel-caption {
	h4 {
		font-size : 4em;
	}
	p {
		margin-top : 10px;
		font-size : 2em;
	}	
}
}
@media only screen and (min-width: @screen-md) and (max-width: @screen-lg) {
.carousel-caption {
	h4 {
		font-size : 4.5em;
	}
	p {
		margin-top : 15px;
		font-size : 2.5em;
	}	
}
}
@media only screen and (min-width: @screen-lg) and (max-width: 4000px) {
.carousel-caption {
	h4 {
		font-size : 5em;
	}
	p {
		margin-top : 20px;
		font-size : 3em;
	}	
}
}
*/






/*
// Carousel resize
@media only screen and (min-width: 0px) and (max-width: @screen-xs) {
.homecarousel {
    height : 350px;
    .carousel-inner {
        .item {
           height : 350px !important;
            img {
                height : 350px;
            }
        }
    }
    .homestrapline {
        span {
            font-size : 20px !important;
        }
    }
}
}
@media only screen and (min-width: @screen-xs) and (max-width: @screen-sm) {
.homecarousel {
    height : 400px;
    .carousel-inner {
        .item {
           height : 400px !important;
            img {
                height : 400px;
            }
        }
    }
    .homestrapline {
        span {
            font-size : 30px !important;
        }
    }
}
}
@media only screen and (min-width: @screen-sm) and (max-width: @screen-md) {
.homecarousel {
    height : 500px;
    .carousel-inner {
        .item {
           height : 500px !important;
            img {
                height : 500px;
            }
        }
    }
}
}
@media only screen and (min-width: @screen-lg) and (max-width: 3000px) {
.homecarousel {
    height : 800px;
    .carousel-inner {
        .item {
           height : 800px !important;
            img {
                height : 800px;
            }
        }
    }
}
}
*/


// Carousel - ARTIST OF THE WEEK
/*
.art {
    .carousel-inner {
        position : relative;
        .item {
            height : 550px !important;
        }
        .carousel-caption {
            padding-top : 5px;
            padding-bottom : 5px;
            width : 100%;
            position : absolute;
            bottom : 0px !important;
            left : 0px !important;
            color : @brand-primary-one !important;
            background-color : @white-trans !important;
            
            h4 {
                display : none;
                visibility : hidden;
            }
            p {
                line-height : 20px; 
            }
        }
    }
}
*/












// -------------------------------------------
// General content section layout
.homecontentsection {
	.gradient-contentbgbackground;
	position : relative;
	
	.homecontentsectioninner {
		margin-left : auto !important;
		margin-right : auto !important;
		max-width : 1500px !important;
		background-color : transparent;
		position : relative;
		top : -20px;
	}
}



// -------------------------------------------
// Year Hub Links (List Version) - Also for footer links menu
.homeyearlinkslist {
	box-shadow : 0px 0px 7px rgba(0,0,0,0.3);

	// main button style
	li {
		margin-left : 0px !important;
		text-align : center;
		font-weight : 700;
		font-size : 14px;
		
		background-color : @brand-primary-two-light;
		color : @brand-primary-two;

		// Heading
		&:first-child {
			padding : 30px 20px;
			background-color : @brand-primary-two-dark;
			color : @white;
			
			span {
			}
		}

		// buttons links (general)
		a {
			padding : 30px 20px;
			margin-bottom : 0px;
			border-radius : 0px;
			color : @brand-primary-two;
			font-weight : 700;
			font-size : 20px;
			width : 100%;
			height : 100%;
			display : block;
			
			//background-color : @brand-primary-three-light;
			//color : @white;
			
			.transition-header-zeropointtwofive;
			
			&:hover {
				text-decoration : none;
				background-color : @brand-primary-two-lightest;
			}
		}

		// alternate button			
		&:nth-child(2n+3) {
			background-color : @brand-primary-two-lightest;

			a {
				background-color : @brand-primary-two-lightest;
				
				&:hover {
					background-color : @brand-primary-two-lightestmore;
				}
			}
		}
		
		&.eight {
			a {
				background-color : @brand-primary-two-lightest;
				&:hover {
					background-color : @brand-primary-two-lightestmore;
				}
			}
		}
		&.teneleven {
			a {
				background-color : @brand-primary-three-light;
				color : @white;
				&:hover {
					background-color : @brand-primary-three-lightest;
				}
			}
		}
		&.twelvethirteen {
			a {
				background-color : @brand-primary-fasfblue-light;
				color : @white;
				&:hover {
					background-color : @brand-primary-fasfblue-lightish;
				}
			}
		}		
		
	}
}
// Footer version
footer {
.homeyearlinkslist {
	//box-shadow : 0px 0px 7px rgba(0,0,0,0.3);
	position : relative;
	margin-top : -10px;

	li {
		font-size : 14px;

		// Heading
		&:first-child {
			padding : 10px 20px;
		}

		// buttons links (general)
		a {
			padding : 10px 20px;
		}
	}
}
}



// -------------------------------------------
// Year Hub links - NOT CURRENTLY IN USE - See above styles
.homeyearlinks {
	margin-bottom : 30px;
	box-shadow : 0px 0px 7px rgba(0,0,0,0.3);

	.hyltitle {
		.zeropm;
		height : 100px;
		display : table;
		background-color : @brand-primary-two-dark;
		color : @white;
		text-align : center;
		font-weight : 700;
		font-size : 16px;
		
		span {
			height : 100%;
			width : 100%;
			display : table-cell;
			vertical-align : middle;
		}
	}
	.hyllink {
		.zeropm;
		text-align : center;
		height : 100px;
		display : table;
		
		a {
			height : 100%;
			width : 100%;
			display : table-cell;
			vertical-align : middle;
			background-color : @brand-primary-two-light;
			color : @brand-primary-two;
			font-weight : 700;
			font-size : 20px;
			
			.transition-header-zeropointtwofive;
			
			&:hover {
				text-decoration : none;
				background-color : @brand-primary-two-lightest;
			}
		}

		&.eight {
			a {
				background-color : @brand-primary-two-lightest;
				&:hover {
					background-color : @brand-primary-two-lightestmore;
				}
			}
		}
		&.teneleven {
			a {
				background-color : @brand-primary-three-light;
				color : @white;
				&:hover {
					background-color : @brand-primary-three-lightest;
				}
			}
		}
		&.twelvethirteen {
			a {
				background-color : @brand-primary-fasfblue-light;
				color : @white;
				&:hover {
					background-color : @brand-primary-fasfblue-lightish;
				}
			}
		}
	}
}


// -------------------------------------------
// Homepage Spotlights
.homespotlightboxes {
	margin : 20px 0px 0px 0px;
	padding : 0px 0px 0px 0px;
	
	.hslb {
		.zeropm;
		background-size : cover !important;
		background-position : center center !important;
		position : relative;
		height : 300px;
		
		transition : all 0.5s;
		
		a.hslblinkbox {
			color : @brand-primary-one;
			
			width : 100%;
			height : 100%;
			display : inline-block;
			
			position : absolute;
			top : 0px;
			left : 0px;
			
			&:hover {
				text-decoration : none;
			}
			span {
				font-size : 0px;
				display : none;
			}
		}
		
		.post-edit-link {
			font-size : 10px !important;
			
			position : absolute;
			bottom : 18px;
			right : 15px;
			z-index : 20000;
		}
		
		.hslbbox {
			padding : 20px 20px;
			position : absolute;
			top : 0px;
			left : 0px;
			height : 100%;
			width : 100%;
			display : inline-block;
			
			transition : height 0.5s;
			
			.hslbboxinner {
				margin-bottom : 20px;
				border : 0px solid @white;
				height : 100%;
				position : relative;
			
    			.hslbheadbg {
    				width : 100%;
    				background-color : @white-moretrans;
    				
					position : absolute;
					bottom : 0px;
					left : 0px;
    				
    				h3 {
    					.zeropm;
    					padding-top : 5px;
	    				padding-bottom : 5px;
	    				margin-bottom : 1px;
	    				font-size : 17px;
	    				line-height : 18px;
	    				font-weight : bold !important;
	    				text-align : center;
	    				color : @gray;
    				}
    			}
    			
    			.hslbexcerpt { 
    				font-size : 16px;
    				line-height : 18px;
    				//color : @white;
    				
    				opacity: 0.0;
    				filter: alpha(opacity=0);
    				
    				p {
	    				padding : 10px 10px;
	    				font-size : 16px;
	    				line-height : 18px;	
	    				font-weight : bold;
    				}
    			}
			}
		}
		
		&:hover {
			text-decoration : none;
			
    		.hslbbox {
    			background-color : fade(@brand-primary-two, 50%);
				
    			transition : all 0.5s;
    			
    			.hslbboxinner {
    				border : 1px solid @white;
    				background-color : fade(@white-moretrans, 50%);
    				
    				.hslbheadbg {
    					background-color : @white;
    					
    					h3 {
	    					margin-bottom : 0px;
    					}
    				}
    				
    				.hslbexcerpt { 
	    				opacity: 1.0;
	    				filter: alpha(opacity=100);
    				}
    			}	    			
    		}
		}
	}
	@media (min-width: @screen-xs-min) { 
		.hslb {
			height : 400px;
			
			.hslbbox {
				
			}
		}
	} 
	@media (min-width: @screen-sm-min) { 
		.hslb {
			height : 300px;
			
			.hslbbox {
				
			}
		}
	} 
	@media (min-width: @screen-md-min) { 
		.hslb {
			height : 250px;
			
			.hslbbox {
				
			}
		}
	} 
	@media (min-width: @screen-lg-min) { 
		.hslb {
			height : 300px;
			
			.hslbbox {
				
			}
		}
	}	
}


// -------------------------------------------
// Social Media
// see Social.less


// -------------------------------------------
// Emergency Notices Styling
.homeschoolnotice {
	text-align : center;
	
	.hsninner {
		padding : 20px 10px;
		margin : 0px auto;
		max-width : 1500px;
		text-align : left;
		font-size : @font-size-base;
	}
	
	&.snownotice {
		background-color : rgba(255,0,0,0.25);
		
		h2 small {
			color : @white;
		}
	}
}