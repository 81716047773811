
// -------------------------------------------
// KS3 Curriculum
.maindepartmentpage {
	border : 1px solid @white;
	
	.deptslistbox {
		border : 1px solid @white;
		
		.innerpad {
			border : 1px solid @gray-lighter;
		}
		
		.depttitleexcerpt {
			
			h3 {
				margin-top : 0px;
				margin-bottom : 0px !important;
				padding : 10px 10px;
				background-color : #eeeeee;
				font-size : 14px;
				line-height : 16px;
				font-weight : bold !important;
				text-align : left;
				color : #555555;
			}
		}
	}
}


// -------------------------------------------
// KS4 & KS5 Layout
//.page-template-template-curriculum,
//.page-template-template-curriculum-ks4,
//.page-template-template-curriculum-ks5
//{
	
	.maindepartmentpage {
		display: flex;
		flex-wrap: wrap;
		
		
		.deptslistbox {
			.zeropm;
			margin-bottom : 10px;
			border : 1px solid @white;
			display : flex;
			flex-direction : column;
			
			.innerpad {
				border : 1px solid @gray-lighter;
				position : relative;
				
				.deptsbox {
					padding-top : 200px;
					background-size : cover !important;
					background-position : center center !important;
					
					.depttitle {
						
						
						h3 {
							margin-top : 0px;
							margin-bottom : 0px !important;
							padding : 10px 10px;
							background-color : #eeeeee;
							font-size : 14px;
							line-height : 16px;
							font-weight : bold !important;
							text-align : center;
							color : #555555;
							//min-height : 60px;
						}						
					}
				}
				a {
					text-decoration : none;
					cursor : pointer;
					
					.transition-header-zeropointone;
					
					&:hover {
						text-decoration : none;
						
						.depttitle {
							
							h3 {
								background-color : #dddddd;
							}
						}
					}
				}
				.deptbuttons {
					padding : 10px 5px;
					background-color : @gray-light;
					text-align : center;
				}
				.editbutton {
					display : inline;
					position : absolute;
					top: 10px;
					right : 10px;
					z-index : 400;
				}
			}
			
		}		
	}
//} // END .page-template-template-curriculum-ks4


// -------------------------------------------
// GCSE Hub
.courseresources {

	.crgrid {
		display : flex; 
		flex-wrap : wrap;
		
		.crgridelement {
			position : relative; 
			margin-bottom : 20px; 
			display : flex; 
			flex-direction : column;
			
			.crgelink {
				margin : 5px; 
				padding-top : 20px; 
				padding-bottom : 20px; 
				width : 100%;
			}
			
			.editbutton {
				display : inline; 
				position : absolute; 
				top: 10px; 
				right : 25px; 
				z-index : 400;
			}
		}
	}
}

.gcsehubcontlayout {
	
	
	h4.ghcltitleboard {
		margin-top : 0px;
		margin-bottom : 0px;
		font-weight : 500!important;
	}
	h3.ghcltitlemain {
		margin-top : 0px;
		margin-left : -2px;
		font-size : @font-size-h1;
		font-weight : 700!important;
	}
}